import React, { useEffect, useState } from 'react';
import useAuth from '../../../hooks/useAuth';
import { Button, Wrapper } from '../../../component';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import { ContentSection } from './types';
import { IoMdAdd } from 'react-icons/io';
import { MdDelete, MdEdit } from 'react-icons/md';

function Sections() {
  const { authToken } = useAuth();
  const [contents, setContents] = useState<ContentSection[]>([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [sectionToDelete, setSectionToDelete] = useState<string | null>(null);

  useEffect(() => {
    const fetchConfigurations = async () => {
      try {
        const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_TECNOFICOM}/contents`, { headers });
        setContents(response.data);
      } catch (error) {
        toast.error('Hubo un problema al cargar las configuraciones.');
      }
    };

    fetchConfigurations();
  }, [authToken]);

  const handleDeleteSection = async () => {
    if (!sectionToDelete) return;

    try {
      const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
      await axios.delete(`${process.env.REACT_APP_BACKEND_TECNOFICOM}/contents/${sectionToDelete}`, { headers });
      setContents((prev) => prev.filter((content) => content.id !== sectionToDelete));
      toast.success('Sección eliminada correctamente.');
    } catch (error) {
      toast.error('Hubo un problema al eliminar la sección.');
    } finally {
      setShowConfirmModal(false);
      setSectionToDelete(null);
    }
  };

  const openDeleteModal = (id: string) => {
    setSectionToDelete(id);
    setShowConfirmModal(true);
  };

  const closeDeleteModal = () => {
    setShowConfirmModal(false);
    setSectionToDelete(null);
  };

  return (
    <Wrapper>
      <ToastContainer />
      <h3 className="text-tecnofi-text text-lg font-semibold text-center mt-6 mb-6">
        Administración de Contenidos
      </h3>
      <section className="flex justify-start items-center w-full gap-2 m-2">
        <span className="flex items-center gap-2">
          <Link
            to={`/dashboard/contents/create`}
            className="bg-tecnofi-primary rounded-[8px] p-[4px] text-white"
          >
            <IoMdAdd size="20" fill="#fff" />
          </Link>
          <p className="text-tecnofi-text text-base select-none">
            Crear Sección
          </p>
        </span>
      </section>

      <section className="mt-4">
        {contents.length === 0 ? (
          <p>No hay contenidos disponibles.</p>
        ) : (
          contents.map((content) => (
            <article key={content.id} className="bg-tecnofi-section relative p-4 mb-4 rounded-[8px]">
              <span className="flex justify-center items-start gap-2 absolute top-4 right-4">
                <Link
                  to={`/dashboard/contents/update/${content.id}`}
                  className="bg-tecnofi-primary rounded-[8px] p-[4px]"
                >
                  <MdEdit size={20} fill="#FFF" />
                </Link>
                <Button
                  variant="Primary"
                  className="rounded-[8px] p-[4px]"
                  onClick={() => content.id && openDeleteModal(content.id)}
                >
                  <MdDelete size={20} fill="#FFF" />
                </Button>
              </span>
              <h4 className="text-tecnofi-text text-lg font-medium">Nombre del Contenido: {content.name}</h4>
              {content.content && content.content.length > 0 ? (
                <div className="space-y-1">
                  {content.content.map((item, idx) => (
                    <div key={idx} className="bg-tecnofi-section p-2 rounded-lg">
                      <h4 className="text-xl font-medium text-tecnofi-text">{item.name} {item.isHighlighted ? ' - Destacado' : ''}</h4>

                      {item.contents && item.contents.length > 0 ? (
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 mt-2">
                          {item.contents.map((subItem, subIdx) => (
                            <div
                              key={subIdx}
                              className={`bg-tecnofi-background text-tecnofi-text p-4 rounded-lg`}
                            >
                              {subItem.url ? (
                                <Link
                                  to={subItem.url}
                                  className="block"
                                >
                                  {subItem.name && (
                                    <h6 className="font-medium text-lg mb-2">{subItem.name}</h6>
                                  )}
                                  {subItem.image && (
                                    <img
                                      src={subItem.image}
                                      alt={subItem.name}
                                      className="h-auto w-auto max-h-40 rounded-lg mx-auto mb-2"
                                    />
                                  )}
                                </Link>
                              ) : (
                                <>
                                  {subItem.name && (
                                    <h6 className="font-medium text-lg mb-2">{subItem.name}</h6>
                                  )}
                                  {subItem.image && (
                                    <img
                                      src={subItem.image}
                                      alt={subItem.name}
                                      className="h-auto w-auto max-h-40 rounded-lg mx-auto mb-2"
                                    />
                                  )}
                                </>
                              )}
                            </div>
                          ))}
                        </div>
                      ) : (
                        <p className="mt-2 text-tecnofi-text">No hay contenidos disponibles en esta sección.</p>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-center text-tecnofi-text mt-6">No hay contenidos disponibles.</p>
              )}
            </article>
          ))
        )}
      </section>

      {showConfirmModal && (
        <section className="fixed z-50 inset-0 bg-[#4444] flex justify-center items-center">
          <article className="flex flex-col bg-tecnofi-background p-4 rounded-lg shadow-md w-1/3">
            <h3 className="text-tecnofi-text text-lg font-medium mb-4">
              ¿Estás seguro de que quieres eliminar esta sección?
            </h3>

            <div className="flex justify-between">
              <Button className="rounded px-4 py-2" onClick={closeDeleteModal}>
                Cancelar
              </Button>
              <Button
                variant="Primary"
                className="rounded px-4 py-2"
                onClick={handleDeleteSection}
              >
                Confirmar
              </Button>
            </div>
          </article>
        </section>
      )}
    </Wrapper>
  );
}

export default Sections;
