import React, { useState } from 'react';
import SectionForm from '../components/SectionForm';
import { Section } from '../components/types';
import { Button } from '../../../component';
import { TiArrowDown, TiArrowUp, TiDocumentDelete, TiEdit, TiFolderDelete } from 'react-icons/ti';
import { Link } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { ContentSection } from './types';

function CreateSections() {
  const { authToken } = useAuth();
  const [edit, setEdit] = useState<ContentSection | null>(null);
  const [sections, setSections] = useState<Section[]>([]);
  const [name, setName] = useState<string>('');
  const [editingContentIndex, setEditingContentIndex] = useState<number | null>(null);

  const handleAddSection = (contentData: Section, index: number | null) => {
    const updatedSections = [...sections];
    if (index !== null && updatedSections[index]) {
      updatedSections[index] = contentData;
    } else {
      updatedSections.push(contentData);
    }
    setSections(updatedSections);
    setEditingContentIndex(null);
  };

  const handleEdit = (contentIndex: number) => {
    const sectionToEdit = { name, content: sections };
    setEditingContentIndex(contentIndex);
    setEdit(sectionToEdit);
    console.log(edit, contentIndex);
  };

  const handleDeleteSection = (index: number) => {
    setSections(sections.filter((_, i) => i !== index));
  };

  const handleMoveSection = (index: number, direction: 'up' | 'down') => {
    const updatedSections = [...sections];
    const targetIndex = direction === 'up' ? index - 1 : index + 1;

    if (targetIndex >= 0 && targetIndex < updatedSections.length) {
      [updatedSections[index], updatedSections[targetIndex]] = [
        updatedSections[targetIndex],
        updatedSections[index],
      ];
      setSections(updatedSections);
    }
  };

  const handleLogSections = async () => {
    try {
      if (!name || sections.length === 0) {
        toast.error('El nombre y el contenido son requeridos.');
        return;
      }
      const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
      const body = {
        name,
        content: sections,
      };
      await axios.post(
        `${process.env.REACT_APP_BACKEND_TECNOFICOM}/contents`,
        body,
        { headers }
      );
      toast.success('Configuraciones actualizadas correctamente.');
    } catch (error) {
      console.error('Error al actualizar configuraciones:', error);
      toast.error('Hubo un problema al actualizar las configuraciones.');
    }
  };

  return (
    <section className="p-6">
      <ToastContainer />
      <h3 className="text-center text-lg font-semibold mb-6">Crear Secciones</h3>
      <Button
        variant="Primary"
        onClick={handleLogSections}
        className="mb-6 w-full py-2 rounded-lg"
      >
        Subir Cambios
      </Button>
      <input
        type="text"
        placeholder="Nombre de la sección"
        className="bg-tecnofi-background font-medium border-[2px] border-tecnofi-primary rounded-[8px] w-full py-2 px-4 mb-2 focus:outline-none"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />

      <SectionForm
        key={editingContentIndex}
        onSave={handleAddSection}
        section={edit}
        contentIndex={editingContentIndex}
      />


      <article>
        {sections.map((section, index) => (
          <div
            key={index}
            className="mb-6 border-[2px] border-tecnofi-primary p-6 rounded bg-tecnofi-section"
          >
            <article className="flex justify-between items-center">
              <h4 className="font-medium text-lg">{section.name}</h4>
              <span className="flex flex-col justify-center items-center gap-2">
                <Button
                  variant="Primary"
                  className="text-[#FFF] rounded-lg p-[4px]"
                  onClick={() => handleDeleteSection(index)}
                >
                  <TiFolderDelete size={26} fill="#FFF" />
                </Button>
                <Button
                  variant="Primary"
                  className="text-[#FFF] rounded-lg p-[4px]"
                  onClick={() => handleEdit(index)}
                >
                  <TiEdit size={26} fill="#FFF" />
                </Button>
                <Button
                  variant="Primary"
                  className="text-[#FFF] rounded-lg p-[4px]"
                  onClick={() => handleMoveSection(index, 'up')}
                  disabled={index === 0}
                >
                  <TiArrowUp size={26} fill="#FFF" />
                </Button>
                <Button
                  variant="Primary"
                  className="text-[#FFF] rounded-lg p-[4px]"
                  onClick={() => handleMoveSection(index, 'down')}
                  disabled={index === sections.length - 1}
                >
                  <TiArrowDown size={26} fill="#FFF" />
                </Button>
              </span>
            </article>
            <p className="text-sm mb-4">
              {section.isHighlighted ? 'Sección destacada' : 'Sección no destacada'}
            </p>
            <article className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {section.contents?.map((content, i) => (
                <div
                  key={i}
                  className="relative p-4 border-[2px] border-tecnofi-primary rounded bg-white shadow-sm flex flex-col justify-between"
                >
                  <div>
                    {content.name && (
                      <p className="mb-2">
                        <span className="font-medium">Nombre del Contenido: </span>
                        {content.name}
                      </p>
                    )}
                    {content.image && (
                      <div className="mb-2">
                        <span className="font-medium">Imagen del Contenido: </span>
                        <img
                          src={content.image}
                          alt={content.name || 'Imagen del contenido'}
                          className="h-auto w-auto max-h-40 rounded border-[2px] border-tecnofi-primary mx-auto mb-2 object-cover"
                        />
                      </div>
                    )}
                    {content.url && (
                      <p className="mb-2">
                        <span className="font-medium">Link del Contenido: </span>
                        <Link
                          to={content.url}
                          className="text-tecnofi-primary line-clamp-1 underline"
                        >
                          {content.url}
                        </Link>
                      </p>
                    )}
                  </div>
                </div>
              ))}
            </article>
          </div>
        ))}
      </article>
    </section>
  );
}

export default CreateSections;