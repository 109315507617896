import React, { useState } from 'react';
import { Button } from '../../../component';
import useAuth from '../../../hooks/useAuth';
import axios from 'axios';
import { MdOutlineSimCardDownload } from 'react-icons/md';
import { toast } from 'react-toastify';

function ReportSales() {
  const { authToken } = useAuth();
  const [reportRange, setReportRange] = useState('day');
  const [selectedDate, setSelectedDate] = useState('');

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(e.target.value);
  };

  const downloadReport = async () => {
    try {
      const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
      const query = `period=${reportRange}&date=${selectedDate}`;
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_TECNOFICOM}/reports/generate-sales?${query}`,
        { headers, responseType: 'blob' }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `sales_report_${reportRange}_${selectedDate}.xlsx`);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      toast.success('Archivo descargado.');
    } catch (error) {
      toast.error('Hubo un problema al descargar el archivo.');
    }
  };

  return (
    <>
      <section className="flex flex-col p-6 bg-tecnofi-section text-tecnofi-text rounded-lg shadow-md mb-4">
        <h3 className="text-lg font-semibold mb-4">Descargar Reporte de Ventas</h3>
        <article className="mb-4">
          <label htmlFor="reportRange" className="block border-tecnofi-primary mb-2 text-sm font-medium">
            Seleccione el rango del reporte:
          </label>
          <select
            id="reportRange"
            value={reportRange}
            onChange={(e) => setReportRange(e.target.value)}
            className="p-2 border rounded-md w-full focus:outline-none border-tecnofi-primary"
          >
            <option value="day">Día</option>
            <option value="week">Semana</option>
            <option value="month">Mes</option>
          </select>
        </article>
        <article className="mb-4">
          <label htmlFor="selectedDate" className="block mb-2 text-sm font-medium focus:outline-none">
            Seleccione una fecha:
          </label>
          <input
            type="date"
            id="selectedDate"
            value={selectedDate}
            onChange={handleDateChange}
            className="p-2 border border-tecnofi-primary rounded-md w-full focus:outline-none text-tecnofi-text"
          />

        </article>
        <Button
          variant="Primary"
          onClick={downloadReport}
          className="flex items-center gap-2 bg-tecnofi-button text-white px-2 py-2 rounded-md"
        >
          <MdOutlineSimCardDownload
            size={22}
            fill="#FFF"
          />
          Descargar Reporte
        </Button>
      </section>
    </>
  );
}

export default ReportSales;