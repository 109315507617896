import React, { useEffect, useState } from 'react';
import useAuth from '../../../hooks/useAuth';
import axios from 'axios';
import { Category } from './types';
import { Button, Wrapper } from '../../../component';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { MdOutlineCreate } from 'react-icons/md';
import { IoMdTrash } from 'react-icons/io';

function GetCategories() {
  const { authToken } = useAuth();
  const [categoriesLevelOne, setCategoriesLevelOne] = useState<Category[]>([]);
  const [categoriesLevelTwo, setCategoriesLevelTwo] = useState<Category[]>([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState<string | null>(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
        const categoriesLevelOneResponse = await axios.get<Category[]>(
          `${process.env.REACT_APP_BACKEND_TECNOFICOM}/category?level=1`,
          { headers }
        );
        const categoriesLevelTwoResponse = await axios.get<Category[]>(
          `${process.env.REACT_APP_BACKEND_TECNOFICOM}/category?level=2`,
          { headers }
        );
        setCategoriesLevelOne(categoriesLevelOneResponse.data);
        setCategoriesLevelTwo(categoriesLevelTwoResponse.data);
      } catch (error) {
        toast.error('Error del backend al solicitar las categorías.');
      }
    };

    fetchCategories();
  }, [authToken]);

  const deleteCategory = async (id: string) => {
    if (!id) {
      toast.error('ID de categoría no proporcionado.');
      return;
    }

    try {
      const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
      await axios.delete(`${process.env.REACT_APP_BACKEND_TECNOFICOM}/category/${id}`, { headers });
      toast.info('Se ha eliminado la categoría correctamente.');
      setShowConfirmModal(false);
      setCategoryToDelete(null);
    } catch (error) {
      toast.error('Error del backend al eliminar la categoría.');
    }
  };

  const handleDeleteClick = (id: string) => {
    setCategoryToDelete(id);
    setShowConfirmModal(true);
  };

  const handleCloseModal = () => {
    setShowConfirmModal(false);
    setCategoryToDelete(null);
  };

  return (
    <Wrapper>
      {showConfirmModal && (
        <section className="fixed z-50 inset-0 bg-gray-800 bg-[#4444] flex justify-center items-center">
          <article className="flex flex-col bg-tecnofi-background p-4 rounded-lg shadow-md w-1/3">
            <h3 className="text-tecnofi-text text-lg font-medium mb-4">
              ¿Estás seguro de que quieres eliminar esta categoría?
            </h3>
            <span className="flex justify-between">
              <Button
                className="rounded px-4 py-2"
                onClick={handleCloseModal}
              >
                Cancelar
              </Button>
              <Button
                variant="Primary"
                className="rounded px-4 py-2"
                onClick={() => categoryToDelete && deleteCategory(categoryToDelete)}
              >
                Confirmar
              </Button>
            </span>
          </article>
        </section>
      )}

      <h3 className="flex text-tecnofi-text text-lg font-semibold text-center mt-4 mb-2">
        Administración de Categorías
      </h3>
      <span className="flex items-center gap-2 m-2">
        <Link
          to={`/dashboard/admin-products/categories/create`}
          className="bg-tecnofi-primary rounded-[8px] p-[8px] text-white"
        >
          <MdOutlineCreate size="16" fill="#fff" />
        </Link>
        <p className="text-tecnofi-text text-base">Crear Categoria</p>
      </span>
      <section className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <article>
          <h4 className="text-tecnofi-text text-md font-semibold mb-4">Categorías Nivel 1</h4>
          <table className="table-auto w-full text-left">
            <tbody>
              {categoriesLevelOne.map((category) => (
                <tr key={category.id} className="border-t border-b border-tecnofi-text">
                  <td className="px-2 py-1 text-tecnofi-text">{category.id}</td>
                  <td className="px-2 py-1 text-tecnofi-text">{category.name}</td>
                  <td>
                    <Button
                      type="button"
                      variant="Primary"
                      className="flex relative rounded-[4px] my-auto px-[4px] py-[4px]"
                      onClick={() => handleDeleteClick(category.id)}
                    >
                      <IoMdTrash size={16} fill="#FFF" />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </article>
        <article>
          <h4 className="text-tecnofi-text text-md font-semibold mb-4">Categorías Nivel 2</h4>
          <table className="table-auto w-full text-left">
            <tbody>
              {categoriesLevelTwo.map((category) => (
                <tr key={category.id} className="border-t border-b border-tecnofi-text">
                  <td className="px-2 py-1 text-tecnofi-text">{category.id}</td>
                  <td className="px-2 py-1 text-tecnofi-text">{category.name}</td>
                  <td>
                    <Button
                      type="button"
                      variant="Primary"
                      className="flex relative rounded-[4px] my-auto px-[4px] py-[4px]"
                      onClick={() => handleDeleteClick(category.id)}
                    >
                      <IoMdTrash size={16} fill="#FFF" />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </article>
      </section>
    </Wrapper >
  );
}

export default GetCategories;