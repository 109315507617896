import React, { useState } from 'react';
import useAuth from '../../../hooks/useAuth';
import axios from 'axios';
import { Button, Wrapper } from '../../../component';
import { toast } from 'react-toastify';

function CreateCategories() {
  const { authToken } = useAuth();
  const [name, setName] = useState('');
  const [level, setLevel] = useState(1);
  const [parentCategoryId, setParentCategoryId] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newCategory = {
      name,
      level,
      parentCategoryId: parentCategoryId || null,
    };

    try {
      setLoading(true);
      const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
      await axios.post(
        `${process.env.REACT_APP_BACKEND_TECNOFICOM}/category`,
        newCategory,
        { headers }
      );
      toast.success('Categoría creada exitosamente');
      setName('');
      setLevel(1);
      setParentCategoryId(null);
    } catch (error) {
      toast.error('Error al crear la categoría');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Wrapper isContent>
      <form className="max-w-lg mx-auto mt-5" onSubmit={handleSubmit}>
        <h3 className="text-lg font-semibold mb-4 text-tecnofi-text text-center ">Crear Nueva Categoría</h3>
        <article className="mb-4">
          <label className="flex text-base font-medium mb-2 text-tecnofi-text" htmlFor="name">
            Nombre de la Categoría
          </label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="flex justify-start items-center border-[2px] border-tecnofi-primary rounded-[8px] text-tecnofi-text w-full px-4 h-[52px] bg-tecnofi-section placeholder-tecnofi-faded-text focus:outline-none"
            placeholder="Nombre de la categoría"
            required
          />
        </article>
        <article className="mb-4">
          <label className="flex text-base font-medium mb-2 text-tecnofi-text" htmlFor="level">
            Nivel de la Categoría
          </label>
          <select
            id="level"
            value={level}
            onChange={(e) => setLevel(Number(e.target.value))}
            className="flex justify-start items-center border-[2px] border-tecnofi-primary rounded-[8px] text-tecnofi-text w-full px-4 h-[52px] bg-tecnofi-section focus:outline-none"
          >
            <option value={1}>Nivel 1</option>
            <option value={2}>Nivel 2</option>
          </select>
        </article>
        <article className="mb-4">
          <label
            className="flex text-base font-medium mb-2 text-tecnofi-text"
            htmlFor="parentCategoryId"
          >
            ID de Categoría Padre (opcional)
          </label>
          <input
            type="text"
            id="parentCategoryId"
            value={parentCategoryId || ''}
            onChange={(e) => setParentCategoryId(e.target.value || null)}
            className="flex justify-start items-center border-[2px] border-tecnofi-primary rounded-[8px] text-tecnofi-text w-full px-4 h-[52px] bg-tecnofi-section placeholder-tecnofi-faded-text"
            placeholder="ID de la categoría padre"
          />
        </article>
        <Button
        variant="Primary"
          type="submit"
          className="w-full py-3 rounded-[8px] font-medium"
          disabled={loading}
        >
          {loading ? 'Creando...' : 'Crear Categoría'}
        </Button>
      </form>
    </Wrapper>
  );
}

export default CreateCategories;