import React, { useState, useEffect, useRef } from 'react';
import { MainBannerProps } from './types';
import gsap from 'gsap';
import { Link } from 'react-router-dom';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

function MainBanner({ setBanner }: MainBannerProps) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const imageRef = useRef<HTMLImageElement>(null);
  const totalBanners = setBanner.content?.[0]?.contents?.length || 0;

  useEffect(() => {
    if (totalBanners === 0) return;

    const interval = setInterval(() => {
      goToNext();
    }, 12000);

    return () => clearInterval(interval);
  }, [totalBanners]);

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % totalBanners);
  };

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + totalBanners) % totalBanners);
  };

  useEffect(() => {
    if (imageRef.current) {
      gsap.fromTo(imageRef.current, { opacity: 0.5 }, { opacity: 1, duration: 0.2, ease: 'power2.inOut' });
    }
  }, [currentIndex]);

  if (!setBanner.content || totalBanners === 0) {
    return <p>No hay banners disponibles.</p>;
  }

  const currentBanner = setBanner.content[0];
  const currentContent = currentBanner?.contents?.[currentIndex];

  if (!currentContent) {
    return <p>No hay contenido para este banner.</p>;
  }

  return (
    <section className="flex relative w-full xl:h-[780px] md:h-[500px] sm:h-[300px] xs:h-[260px] h-[200px] text-center overflow-hidden">
      <button
        className="flex justify-center items-center absolute w-16 h-full top-0 left-0 z-20"
        style={{
          background: 'linear-gradient(to left, transparent, #4567)',
        }}
        onClick={goToPrevious}
      >
        <FaChevronLeft size={26} fill="#FFF" />
      </button>

      <Link to={`${currentContent.url}`} className="relative w-full h-full block">
        <img
          ref={imageRef}
          src={currentContent.image}
          alt={currentContent.name}
          className={`object-cover w-full h-full ${currentContent.name ? 'brightness-75' : ''}`}
        />
        {currentContent.name && (
          <p className="text-[#FFF] text-2xl font-medium absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            {currentContent.name}
          </p>
        )}
      </Link>

      <button
        className="flex justify-center items-center absolute w-16 h-full top-0 right-0 z-20"
        style={{
          background: 'linear-gradient(to right, transparent, #4567)',
        }}
        onClick={goToNext}
      >
        <FaChevronRight size={26} fill="#FFF" />
      </button>
    </section>
  );
}

export default MainBanner;