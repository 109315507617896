import React, { useState, useEffect } from 'react';
import { Button, Wrapper } from '../../../component';
import useAuth from '../../../hooks/useAuth';
import axios from 'axios';
import { MdOutlineSimCardDownload } from 'react-icons/md';
import { toast, ToastContainer } from 'react-toastify';
import { Quote, QuotesResponse } from './types';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { RiFileList3Line } from 'react-icons/ri';

function GetQuote() {
  const { authToken } = useAuth();
  const [pagina, setPagina] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(1);
  const [quotes, setQuotes] = useState<QuotesResponse | null>(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedQuote, setSelectedQuote] = useState<Quote | null>(null);
  const [toggleSelected, setToggleSelected] = useState(false);
  const [paymethod, setPaymethod] = useState<string | null>(null);
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
        const response = await axios.get<QuotesResponse>(
          `${process.env.REACT_APP_BACKEND_TECNOFICOM}/quote`,
          {
            headers,
            params: { offset: pagina - 1 },
          },
        );
        setQuotes(response.data);
        setTotalPaginas(response.data.totalPages);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [authToken, pagina]);

  const downloadQuote = async (id: string, customId: string) => {
    try {
      const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_TECNOFICOM}/quote/${id}`,
        { headers, responseType: 'blob' }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `quote_${customId}.pdf`);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      toast.success('Archivo descargado.');
    } catch (error) {
      toast.error('Hubo un problema al descargar el archivo.');
    }
  }

  const convertQuote = async () => {
    if (!selectedQuote || !paymethod) {
      toast.error('Por favor, selecciona un método de pago.');
      return;
    }
    setShowConfirmModal(false);
    try {
      const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_TECNOFICOM}/convert-quote`,
        { id: selectedQuote.id, payMethod: paymethod },
        { headers }
      );
      toast.success('Tu cotización se ha convertido en orden.');
    } catch (error) {
      toast.error('Hubo un problema al convertir la cotización.');
    }
  };

  const handleNextPage = () => {
    if (pagina < totalPaginas) setPagina(pagina + 1);
  };

  const handlePreviousPage = () => {
    if (pagina > 1) setPagina(pagina - 1);
  };

  const togglePaymethod = (method: string) => {
    setPaymethod(method);
    setToggleSelected(false);
  };

  return (
    <Wrapper>
      <ToastContainer />
      {totalPaginas > 1 && (
        <section className="flex justify-between items-center font-medium my-4">
          <Button
            type="button"
            className="rounded-full p-[8px] bg-tecnofi-primary text-white"
            onClick={handlePreviousPage}
            disabled={pagina === 1}
          >
            <FaAngleLeft size="22" fill="#fff" />
          </Button>
          <span className="text-tecnofi-text text-center font-medium">
            Página {pagina} de {totalPaginas}
          </span>
          <Button
            type="button"
            className="rounded-full p-[8px] bg-tecnofi-primary text-white"
            onClick={handleNextPage}
            disabled={pagina === totalPaginas}
          >
            <FaAngleRight size="22" fill="#fff" />
          </Button>
        </section>
      )}
      {quotes ? (
        <section>
          {quotes.quotes.map((quote) => (
            <article key={quote.id} className="flex flex-col bg-tecnofi-section text-tecnofi-text p-4 mb-4 rounded-lg shadow-md">
              <div className="flex justify-between items-start">
                <h4 className="text-lg font-semibold">Orden ID: {quote.customId}</h4>
                <div className="flex flex-col justify-between items-end text-sm font-medium gap-2 mt-[-8px] me-[-8px]">
                  <span className="flex justify-end items-center gap-2">
                    Descargar
                    <Button variant="Primary" className="flex relative rounded-[4px] my-auto px-[4px] py-[4px]" onClick={() => downloadQuote(quote.id, quote.customId)}>
                      <MdOutlineSimCardDownload size={20} fill="#FFF" />
                    </Button>
                  </span>
                  <span className="flex justify-end items-center gap-2">
                    Convertir en Orden
                    <Button
                      variant="Primary"
                      className="flex relative rounded-[4px] my-auto px-[4px] py-[4px]"
                      onClick={() => { setSelectedQuote(quote); setShowConfirmModal(true); }}
                    >
                      <RiFileList3Line size={20} fill="#FFF" />
                    </Button>
                  </span>
                </div>
              </div>
              <div className="grid md:grid-cols-3 sm:grid-cols-2">
                <p className="text-sm"><strong>Total:</strong> {(quote.totalQuote ?? 0).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })}</p>
                <p className="text-sm"><strong>Total Items:</strong> {quote.totalItems ?? 0}</p>
                <p className="text-sm"><strong>ReteICA:</strong> {(quote.reteICA ?? 0).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })}</p>
                <p className="text-sm"><strong>ReteFuente:</strong> {(quote.reteFuente ?? 0).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })}</p>
                <p className="text-sm"><strong>Total VAT:</strong> {(quote.totalVAT ?? 0).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })}</p>
                <p className="text-sm"><strong>Creada:</strong> {new Date(quote.createdAt).toLocaleString()}</p>
              </div>
            </article>
          ))}
        </section>
      ) : (
        <p className="text-center text-tecnofi-text">Cargando cotizaciones...</p>
      )}

      {showConfirmModal && selectedQuote && (
        <div className="fixed z-50 inset-0 bg-gray-800 bg-[#4444] flex justify-center items-center">
          <div className="bg-tecnofi-background p-4 rounded-lg shadow-md">
            <h2 className="text-tecnofi-primary text-lg font-bold mb-4">Confirmación</h2>
            <p>¿Estás seguro de que deseas cambiar el estado de la cotización a Orden?</p>
            <div className="relative m-2">
              <label
                className="flex justify-between items-center bg-tecnofi-background font-normal text-base text-tecnofi-text border-[2px] border-tecnofi-primary px-2 py-2 rounded-[8px] cursor-pointer"
                onClick={() => setToggleSelected(!toggleSelected)}
              >
                {paymethod ? paymethod : 'Seleccione el Metodo de Pago'}
              </label>

              {toggleSelected && (
                <div className="absolute top-full left-0 right-0 bg-tecnofi-background border border-tecnofi-primary rounded-lg mt-1 z-50 overflow-hidden shadow-md">
                  <div
                    className="py-2 px-4 cursor-pointer hover:bg-tecnofi-section"
                    onClick={() => togglePaymethod('CREDIT')}
                  >
                    Crédito
                  </div>
                  <div
                    className="py-2 px-4 cursor-pointer hover:bg-tecnofi-section"
                    onClick={() => togglePaymethod('DEBIT')}
                  >
                    Débito
                  </div>
                  <div
                    className="py-2 px-4 cursor-pointer hover:bg-tecnofi-section"
                    onClick={() => togglePaymethod('BANK_TRANSFER')}
                  >
                    Transferencia Bancaria
                  </div>
                </div>
              )}
            </div>

            <div className="mt-4 flex justify-end gap-4">
              <Button
                onClick={() => setShowConfirmModal(false)}
                className="py-2 px-4 rounded-lg"
              >
                Cancelar
              </Button>
              <Button
                variant="Primary"
                onClick={convertQuote}
                className="py-2 px-4 rounded-lg"
              >
                Confirmar
              </Button>
            </div>
          </div>
        </div>
      )}
    </Wrapper>
  );
}

export default GetQuote;
