import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { ProtectedRoutesProps } from "./types";
import { toast } from "react-toastify";

function ProtectedRoutes({ requiredPermissions = [] }: ProtectedRoutesProps) {
  const { isAuthenticated, hasPermission, verifyToken } = useAuth();
  const [redirectPath, setRedirectPath] = useState<string | null>(null);

  useEffect(() => {
    async function verify() {
      await verifyToken();
    }
    verify();
    if (!isAuthenticated()) {
      setRedirectPath("/login");
      toast.error("Debes iniciar sesión para acceder a esta página.");
    } else if (requiredPermissions.length > 0 && !hasPermission(...requiredPermissions)) {
      setRedirectPath("/");
      toast.error("No tienes permisos para acceder a esta página.");
    } else {
      setRedirectPath(null);
    }
  }, [isAuthenticated, hasPermission, requiredPermissions]);

  if (redirectPath) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
}

export default ProtectedRoutes;
