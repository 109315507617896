import { useState, useEffect } from 'react';
import useAuth from '../../../hooks/useAuth';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { Wrapper } from '../../../component';
import { Button } from '../../../component';
import { MdEdit } from 'react-icons/md';
import { AppConfig, EmailConfig, TaxesConfig, ThemeConfig } from './types';

function GetConfigurations() {
  const { authToken } = useAuth();
  const [configurations, setConfigurations] = useState<AppConfig | null>(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [editValues, setEditValues] = useState<AppConfig | null>(null);
  const [configKeyToEdit, setConfigKeyToEdit] = useState<string | null>(null);
  const [specificKey, setSpecificKey] = useState<string | null>(null);

  useEffect(() => {
    const fetchConfigurations = async () => {
      try {
        const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_TECNOFICOM}/config`, { headers });
        setConfigurations(response.data);
        setEditValues(response.data);
      } catch (error) {
        toast.error('Hubo un problema al cargar las configuraciones.');
      }
    };

    fetchConfigurations();
  }, [authToken]);

  if (!configurations) {
    return <p>Cargando configuraciones...</p>;
  }

  const updatedConfig = async (updatedConfigurations: AppConfig) => {
    try {
      const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
      const payload = {
        id: 1,
        configData: updatedConfigurations,
      };
      await axios.patch(
        `${process.env.REACT_APP_BACKEND_TECNOFICOM}/config`,
        payload,
        { headers }
      );
      toast.success('Configuraciones actualizadas correctamente.');
    } catch (error) {
      console.error('Error al actualizar configuraciones:', error);
      toast.error('Hubo un problema al actualizar las configuraciones.');
    }
  };

  const configKeys: { label: string; key: keyof AppConfig }[] = [
    { label: 'Compra mínima', key: 'minimumPurchase' },
    { label: 'Umbral de retención', key: 'retentionThreshold' }
  ];

  const taxesKeys: (keyof TaxesConfig)[] = ['reteica', 'retefuente'];

  const formatPrice = (price: number) => {
    return new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(price);
  };

  const formatPercentage = (price: number) => {
    return Math.round(price * 100 * 1e10) / 1e10;
  };


  const openEditModal = (configKey: string) => {
    const keys = configKey.split('.');
    const mainKey = keys[0];

    setSpecificKey(configKey);
    setConfigKeyToEdit(mainKey);
    setEditValues(configurations);
    setShowConfirmModal(true);
  };

  const getNestedValue = (obj: any, path: string) => {
    if (!obj || !path) return '';
    return path.split('.').reduce((acc, key) => acc?.[key], obj);
  };

  const handleColorChange = (inputValue: string) => {
    if (!specificKey) return;
    let formattedValue = inputValue.startsWith('#') ? inputValue : `#${inputValue}`;
    const partialHexRegex = /^#([A-Fa-f0-9]{0,6})$/;
    if (!partialHexRegex.test(formattedValue)) {
      return;
    }
    setEditValues((prevValues) => {
      const updatedConfig = { ...prevValues } as AppConfig;
      const keys = specificKey.split('.');
      let currentValue: any = updatedConfig;
  
      for (let i = 0; i < keys.length - 1; i++) {
        if (!currentValue[keys[i]]) {
          currentValue[keys[i]] = {};
        }
        currentValue = currentValue[keys[i]];
      }
      currentValue[keys[keys.length - 1]] = formattedValue;
      return updatedConfig;
    });
  };
  

  const handleCloseModal = () => {
    setShowConfirmModal(false);
    setConfigKeyToEdit(null);
  };

  const handleValueChange = (newValue: string | number) => {
    if (!editValues || !specificKey || !configKeyToEdit) return;

    const updatedConfig = { ...editValues };
    const keys = specificKey.split('.');

    let currentValue: any = updatedConfig;
    for (let i = 0; i < keys.length - 1; i++) {
      const key = keys[i];
      if (!currentValue[key] || typeof currentValue[key] !== 'object') {
        currentValue[key] = {};
      }
      currentValue = currentValue[key];
    }

    const isPercentageKey = keys[0] === 'taxes' || keys[0] === 'extraPercentage';
    let numericValue: number;

    if (typeof newValue === 'string') {
      numericValue = parseFloat(newValue);
      if (isNaN(numericValue)) return;
    } else {
      numericValue = newValue;
    }

    const preciseValue = isPercentageKey
      ? Math.round((numericValue / 100) * 1e5) / 1e5
      : Math.round(numericValue * 1e10) / 1e10;

    const fieldToUpdate = keys[keys.length - 1];
    currentValue[fieldToUpdate] = preciseValue;

    setEditValues(updatedConfig);
    console.log('Configuraciones actualizadas:', updatedConfig);
  };

  const handleEmailChange = (field: keyof EmailConfig, value: string | number) => {
    if (!editValues) return;

    const updatedConfig = {
      ...editValues,
      email: {
        ...editValues.email,
        [field]: value
      }
    };

    setEditValues(updatedConfig);
  };

  const handleSaveChanges = async () => {
    if (editValues) {
      try {
        await updatedConfig(editValues);
        setConfigurations(editValues);
        setShowConfirmModal(false);
        console.log('Configuraciones actualizadas:', editValues);
      } catch (error) {
        console.error('Error al guardar configuraciones:', error);
      }
    }
  };

  return (
    <Wrapper isContent>
      <ToastContainer />
      <h3 className="text-tecnofi-text text-xl font-bold text-center my-8">
        Configuraciones
      </h3>

      <section className="text-tecnofi-text bg-white rounded-lg">
        <div>
          <h3 className="text-lg font-semibold mb-4">Configuraciones del Sistema</h3>

          <div className="space-y-4">
            <h4 className="text-md font-semibold">Impuestos</h4>
            <ul className="space-y-2">
              {taxesKeys.map((tax) => (
                <li key={tax} className="flex items-center justify-between">
                  <span>{tax.charAt(0).toUpperCase() + tax.slice(1)}: {formatPercentage(configurations?.taxes[tax])} %</span>
                  <Button
                    variant="Primary"
                    className="rounded-lg p-2"
                    onClick={() => openEditModal(`taxes.${tax}`)}
                  >
                    <MdEdit size={16} fill='#FFF' />
                  </Button>
                </li>
              ))}
            </ul>
          </div>

          <div className="space-y-4 mt-4">
            <h4 className="text-md font-semibold">Porcentaje Según el Cliente</h4>
            <ul className="space-y-2">
              {Object.entries(configurations?.extraPercentage || {}).map(([key, value]) => (
                <li key={key} className="flex items-center justify-between">
                  <span>{key}: {formatPercentage(value)} %</span>
                  <Button
                    variant="Primary"
                    className="rounded-lg p-2"
                    onClick={() => openEditModal(`extraPercentage.${key}`)}
                  >
                    <MdEdit size={16} fill="#FFF" />
                  </Button>
                </li>
              ))}
            </ul>
          </div>

          <div className="space-y-4 mt-4">
            <h4 className="text-md font-semibold">Límites</h4>
            <ul className="space-y-2">
              {configKeys.map(({ label, key }) => (
                <li key={key} className="flex items-center justify-between">
                  <span>
                    {label}:
                    {typeof configurations?.[key] === 'number'
                      ? formatPrice(configurations[key] as number)
                      : 'No disponible'}
                  </span>
                  <Button
                    variant="Primary"
                    className="rounded-lg p-2"
                    onClick={() => openEditModal(key as string)}
                  >
                    <MdEdit size={16} fill="#FFF" />
                  </Button>
                </li>
              ))}
            </ul>
          </div>

          <div className="space-y-4 mt-4">
            <h4 className="text-md font-semibold">Correo SMTP</h4>
            <ul className="space-y-2">
              <li className="flex items-center justify-between">
                <span>Email: {configurations?.email.user}</span>
                <Button
                  variant="Primary"
                  className="rounded-lg p-2"
                  onClick={() => openEditModal('email.user')}
                >
                  <MdEdit size={16} fill='#FFF' />
                </Button>
              </li>
            </ul>
          </div>
        </div>

        <div>
          <h4 className="text-md font-semibold my-4">Tema</h4>
          <ul className="m-4 space-y-4">
            <li className="flex items-center justify-between">
              <span>Icono:</span>
              <img
                src={configurations?.icon}
                alt="Icono"
                className="bg-tecnofi-section w-auto h-12 rounded-lg border-2 border-tecnofi-primary p-1 cursor-pointer"
                onClick={() => openEditModal('icon')}
              />
            </li>
            <li className="flex items-center justify-between">
              <span>Logo:</span>
              <img
                src={configurations?.logo}
                alt="Logo"
                className="bg-tecnofi-section w-auto h-12 rounded-lg border-2 border-tecnofi-primary p-1 cursor-pointer"
                onClick={() => openEditModal('logo')}
              />
            </li>
          </ul>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {(['light', 'dark'] as Array<keyof ThemeConfig>).map((mode) => (
              <div key={mode} className="rounded-lg">
                <h5 className="text-sm font-semibold capitalize mb-3">{mode} Mode</h5>
                <ul className="space-y-3">
                  {Object.entries(configurations?.theme[mode] || {}).map(([key, color]) => (
                    <li key={key} className="flex items-center justify-between">
                      <span>{key}:</span>
                      <div
                        className="w-8 h-8 rounded-lg border cursor-pointer transition-transform transform hover:scale-105"
                        style={{ backgroundColor: color }}
                        onClick={() => openEditModal(`theme.${mode}.${key}`)}
                        title={`Editar ${key}`}
                      ></div>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </section>

      {showConfirmModal && (
        <section className="fixed z-50 inset-0 bg-[#4444] flex justify-center items-center">
          <article className="flex flex-col bg-tecnofi-background p-4 rounded-lg shadow-md lg:w-1/3 w-3/4">
            <h3 className="text-tecnofi-text text-lg font-medium mb-4">
              {configKeyToEdit === 'icon' && '¿Estás seguro de que quieres cambiar el icono?'}
              {configKeyToEdit === 'logo' && '¿Estás seguro de que quieres cambiar el logo?'}
              {configKeyToEdit === 'email' && '¿Estás seguro de que quieres cambiar el email?'}
              {configKeyToEdit === 'theme' && '¿Estás seguro de que quieres actualizar el tema?'}
              {(configKeyToEdit === 'extraPercentage') && '¿Estás seguro de que quieres actualizar los porcentajes según cliente?'}
              {(configKeyToEdit === 'minimumPurchase' || configKeyToEdit === 'retentionThreshold') && '¿Estás seguro de que quieres actualizar la cantidad en pesos colombianos?'}
              {configKeyToEdit === 'taxes' && '¿Estás seguro de que quieres actualizar los porcentajes de retenciones?'}
            </h3>

            <div className="mb-4">
              {configKeyToEdit === 'icon' && (
                <>
                  <label htmlFor="editValue" className="block text-tecnofi-text text-sm font-semibold mb-2">
                    Inserte la URL del icono:
                  </label>
                  <input
                    type="text"
                    id="editValue"
                    className="mt-1 block w-full px-3 py-2 border-[2px] border-tecnofi-primary rounded-lg focus:outline-none"
                    onChange={(e) => handleValueChange(e.target.value)}
                    placeholder="Escriba la URL del icono"
                  />
                </>
              )}

              {configKeyToEdit === 'logo' && (
                <>
                  <label htmlFor="editValue" className="block text-tecnofi-text text-sm font-semibold mb-2">
                    Inserte la URL del logo:
                  </label>
                  <input
                    type="text"
                    id="editValue"
                    className="mt-1 block w-full px-3 py-2 border-[2px] border-tecnofi-primary rounded-lg focus:outline-none"
                    onChange={(e) => handleValueChange(e.target.value)}
                    placeholder="Escriba la URL del logo"
                  />
                </>
              )}

              {configKeyToEdit === 'email' && (
                <>
                  <label htmlFor="host" className="block text-tecnofi-text text-sm font-semibold mb-2">Host:</label>
                  <input
                    type="text"
                    id="host"
                    value={editValues?.email?.host || ''}
                    className="mt-1 block w-full px-3 py-2 border-[2px] border-tecnofi-primary rounded-lg focus:outline-none mb-2"
                    onChange={(e) => handleEmailChange('host', e.target.value)}
                    placeholder="mail.mail.com"
                  />

                  <label htmlFor="port" className="block text-tecnofi-text text-sm font-semibold mb-2">Puerto:</label>
                  <input
                    type="number"
                    id="port"
                    value={editValues?.email?.port || ''}
                    className="mt-1 block w-full px-3 py-2 border-[2px] border-tecnofi-primary rounded-lg focus:outline-none mb-2"
                    onChange={(e) => handleEmailChange('port', parseInt(e.target.value))}
                  />

                  <label htmlFor="user" className="block text-tecnofi-text text-sm font-semibold mb-2">Correo:</label>
                  <input
                    type="text"
                    id="user"
                    value={editValues?.email?.user || ''}
                    className="mt-1 block w-full px-3 py-2 border-[2px] border-tecnofi-primary rounded-lg focus:outline-none mb-2"
                    onChange={(e) => handleEmailChange('user', e.target.value)}
                    placeholder="correo"
                  />

                  <label htmlFor="pass" className="block text-tecnofi-text text-sm font-semibold mb-2">Contraseña:</label>
                  <input
                    type="password"
                    id="pass"
                    value={editValues?.email?.pass || ''}
                    className="mt-1 block w-full px-3 py-2 border-[2px] border-tecnofi-primary rounded-lg focus:outline-none"
                    onChange={(e) => handleEmailChange('pass', e.target.value)}
                    placeholder="contraseña"
                  />
                </>
              )}

              {configKeyToEdit === 'theme' && (
                <>
                  <label htmlFor="color" className="block text-tecnofi-text text-sm font-semibold mb-2">
                    Seleccione el color:
                  </label>
                  <input
                    type="text"
                    id="color"
                    className="mt-1 block w-full px-3 py-2 border-[2px] border-tecnofi-primary rounded-lg focus:outline-none mb-2"
                    value={specificKey ? getNestedValue(editValues, specificKey) ?? '' : ''}
                    onChange={(e) => handleColorChange(e.target.value)}
                    placeholder="#0B1219"
                  />
                  <div
                    className="w-10 h-10 rounded border mt-2"
                    style={{ backgroundColor: specificKey ? getNestedValue(editValues, specificKey) ?? '#FFFFFF' : '#FFFFFF' }}
                  ></div>
                </>
              )}

              {configKeyToEdit?.startsWith('extraPercentage') && (
                <>
                  <label htmlFor="editValue" className="block text-tecnofi-text text-sm font-semibold mb-2">
                    Porcentaje según cliente:
                  </label>
                  <input
                    type="number"
                    id="editValue"
                    className="mt-1 block w-full px-3 py-2 border-[2px] border-tecnofi-primary rounded-lg focus:outline-none"
                    onChange={(e) => handleValueChange(parseFloat(e.target.value))}
                    placeholder="Escriba el porcentaje nuevo"
                  />
                </>
              )}

              {(configKeyToEdit === 'minimumPurchase' || configKeyToEdit === 'retentionThreshold') && (
                <>
                  <label htmlFor="editValue" className="block text-tecnofi-text text-sm font-semibold mb-2">
                    Nuevo valor en pesos colombianos:
                  </label>
                  <input
                    type="number"
                    id="editValue"
                    className="mt-1 block w-full px-3 py-2 border-[2px] border-tecnofi-primary rounded-lg focus:outline-none"
                    onChange={(e) => handleValueChange(parseFloat(e.target.value))}
                    placeholder="Escriba el valor en pesos colombianos nuevo"
                  />
                </>
              )}

              {configKeyToEdit === 'taxes' && (
                <>
                  <label htmlFor="editValue" className="block text-tecnofi-text text-sm font-semibold mb-2">
                    Porcentaje de retenciones:
                  </label>
                  <input
                    type="number"
                    id="editValue"
                    className="mt-1 block w-full px-3 py-2 border-[2px] border-tecnofi-primary rounded-lg focus:outline-none"
                    onChange={(e) => handleValueChange(parseFloat(e.target.value))}
                    placeholder="Escriba el porcentaje de retención"
                  />
                </>
              )}
            </div>

            <div className="flex justify-between">
              <Button className="rounded px-4 py-2" onClick={handleCloseModal}>
                Cancelar
              </Button>
              <Button
                variant="Primary"
                className="rounded px-4 py-2"
                onClick={handleSaveChanges}
              >
                Confirmar
              </Button>
            </div>
          </article>
        </section>
      )}
    </Wrapper>
  );
}

export default GetConfigurations;