import React, { useState, useEffect } from 'react';
import LogoNavbar from '../header/component/LogoNavbar';
import Login from './component/Login';
import SignUpEmpresa from './component/SignUpEmpresa';
import SignUpPersona from './component/SignUpPersona';
import Button from '../button/Button';
import { LoginProps } from './types';
import { Link } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import axios from 'axios';
import { ContentItem, ContentSection, Section } from '../footer/types';
import { toast } from 'react-toastify';

function Account({ isLoginMode, toggleAuthMode, togglePolitics, toggleLogin }: LoginProps) {
  const { authToken } = useAuth();
  const [typeClient, setTypeClient] = useState(true);
  const [section, setSection] = useState<Section | null>(null);
  const [contentItem, setContentItem] = useState<ContentItem | null>(null);

  useEffect(() => {
    const fetchConfigurations = async () => {
      try {
        const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
        const response = await axios.get<ContentSection[]>(
          `${process.env.REACT_APP_BACKEND_TECNOFICOM}/contents`,
          { headers }
        );

        const mainLoginSection = response.data.find(section => section.name === 'Login');
        if (mainLoginSection && mainLoginSection.content.length > 0) {
          const firstSection = mainLoginSection.content[0];
          setSection(firstSection);
          
          if (firstSection.contents && firstSection.contents.length > 0) {
            setContentItem(firstSection.contents[0]);
          } else {
            setContentItem(null);
          }
        } else {
          toast.error('No se encontró la sección de Login o no hay contenido disponible.');
        }
      } catch (error) {
        toast.error('Hubo un problema al cargar las configuraciones.');
      }
    };

    fetchConfigurations();
  }, [authToken]);

  const handleToggleLogin = () => {
    toggleLogin();
  };

  const handleToggleAuthMode = () => {
    toggleAuthMode();
  };

  const handleToggleTypeClient = () => {
    setTypeClient((prevType) => !prevType);
  };

  return (
    <section className="flex justify-center items-center h-full w-full text-tecnofi-text overflow-y-auto">
      {isLoginMode && contentItem && contentItem.image && (
        <div className="relative hidden md:flex rounded-[32px] xl:w-[50%] lg:w-[60%] md:w-[65%] pe-5">
          <img
            src={contentItem.image}
            alt="Inicie Sesión"
            className={`rounded-[32px] object-cover h-full w-full ${section?.name ? 'brightness-75' : 'opacity-100'
              } transition-opacity duration-300`}
          />
          {section?.name && (
            <div className="absolute inset-0 flex justify-center items-center">
              <h2 className="text-[#FFF] text-3xl font-bold text-center p-4 rounded-lg">
                {section.name}
              </h2>
            </div>
          )}
        </div>
      )}
      <div className={`relative ${isLoginMode ? 'xl:w-[50%] lg:w-[40%] md:w-[35%] h-full overflow-y-auto' : 'max-h-full h-full w-full'}`}>
        <article className="flex flex-col justify-center items-center gap-4 pb-4">
          <LogoNavbar />
          <div className="flex flex-wrap justify-center items-center gap-4">
            <h2 className="text-center text-[32px] font-medium">{isLoginMode ? 'Acceda' : 'Regístrese'}</h2>
            {!isLoginMode && (
              <Button
                variant="Primary"
                className="flex justify-center text-[16px] text-center font-medium mx-auto rounded-lg py-2 px-3 "
                onClick={handleToggleTypeClient}
              >
                {typeClient ? 'Soy persona natural' : 'Soy una empresa'}
              </Button>
            )}
          </div>
        </article>

        {isLoginMode ? (
          <Login toggleLogin={toggleLogin} />
        ) : (
          <>
            {typeClient ? <SignUpEmpresa togglePolitics={togglePolitics} /> : <SignUpPersona togglePolitics={togglePolitics} />}
          </>
        )}

        <p className="text-center text-sm mt-4">
          {isLoginMode ? '¿Aún no tiene una cuenta?' : '¿Ya tiene una cuenta?'}
          <button onClick={handleToggleAuthMode} className="text-tecnofi-primary ml-2 hover:underline focus:outline-none">
            {isLoginMode ? 'Regístrese' : 'Acceda'}
          </button>
        </p>
        <p className="text-center text-sm mt-4">
          {isLoginMode && (
            <>
              ¿Olvidaste tu contraseña?
              <Link to={"/user/forgotten-password"} className="text-tecnofi-primary ml-2 hover:underline focus:outline-none" onClick={handleToggleLogin}>
                Recuperar contraseña
              </Link>
            </>
          )}
        </p>
      </div>
    </section>
  );
}

export default Account;