import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Dashboard() {
  return (
    <>
      <Helmet>
        <title>Tecnoficom | Dashboard</title>
        <meta
          name="description"
          content="Administra y supervisa todas las funciones clave de Tecnoficom desde un solo lugar. Accede a reportes, gestiona usuarios, configura permisos y optimiza la administración interna."
        />
        <meta
          name="keywords"
          content="Tecnoficom, dashboard, administración, gestión empresarial, supervisión, reportes, control de usuarios, análisis de datos, herramientas administrativas"
        />
      </Helmet>
      <div className="flex flex-col justify-center items-center text-tecnofi-text my-20 px-2">
        <h3 className="text-3xl font-semibold text-center">
          Bienvenido al Dashboard de Administración
        </h3>
        <p className="font-medium mt-2">
          Accede a herramientas clave para la gestión eficiente de Tecnoficom.
        </p>
      </div>
    </>
  );
};

export default Dashboard;
