import React, { useEffect, useState } from 'react';
import { Button } from '../../../component';
import { Link, useParams } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import axios from 'axios';
import { TiDocumentDelete, TiFolderDelete, TiEdit, TiArrowUp, TiArrowDown } from 'react-icons/ti';
import SectionForm from '../components/SectionForm';
import { ToastContainer, toast } from 'react-toastify';
import { ContentItem, ContentSection, Section } from './types';

function UpdateSections() {
  const { authToken } = useAuth();
  const { id } = useParams<{ id: string }>();

  const [section, setSection] = useState<ContentSection | null>(null);
  const [name, setName] = useState<string>('');
  const [content, setContent] = useState<Section[] | null>(null);
  const [editingContentIndex, setEditingContentIndex] = useState<number | null>(null);

  useEffect(() => {
    const fetchSectionData = async () => {
      try {
        const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
        const response = await axios.get<ContentSection>(`${process.env.REACT_APP_BACKEND_TECNOFICOM}/contents/${id}`, { headers });
        setSection(response.data);
        if (response.data) {
          setName(response.data.name);
          setContent(response.data.content);
        }
      } catch (error) {
        console.error("Error al obtener los datos de la sección:", error);
        toast.error('Hubo un problema al cargar la sección.');
      }
    };
    fetchSectionData();
  }, [authToken, id]);

  const handleUpdateSection = async () => {
    try {
      if (!section || !hasChanges()) {
        toast.info('No hay cambios para actualizar.');
        return;
      }

      if (!name || !content || content.length === 0) {
        toast.error('El nombre y el contenido son requeridos.');
        return;
      }

      const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
      const updatedSection = { name, content };

      await axios.patch(
        `${process.env.REACT_APP_BACKEND_TECNOFICOM}/contents/${id}`,
        updatedSection,
        { headers }
      );

      toast.success('Sección actualizada correctamente.');
    } catch (error) {
      console.error('Error al actualizar la sección:', error);
      toast.error('Hubo un problema al actualizar la sección.');
    }
  };

  const hasChanges = (): boolean => {
    if (!section) return false;
    const isNameChanged = name !== section.name;
    const isContentChanged = JSON.stringify(content) !== JSON.stringify(section.content);
    return isNameChanged || isContentChanged;
  };

  const handleSave = (contentData: Section, index: number | null) => {
    if (index !== null) {
      const updatedContent = content ? [...content] : [];
      updatedContent[index] = contentData;
      setContent(updatedContent);
    } else {
      const updatedContent = content ? [...content, contentData] : [contentData];
      setContent(updatedContent);
    }
    setEditingContentIndex(null);
  };

  const handleEdit = (contentIndex: number) => {
    setEditingContentIndex(contentIndex);
  };

  const handleMoveSection = (index: number, direction: 'up' | 'down') => {
    if (!content) return;
    const updatedSections = [...content];
    const targetIndex = direction === 'up' ? index - 1 : index + 1;
    if (targetIndex >= 0 && targetIndex < updatedSections.length) {
      [updatedSections[index], updatedSections[targetIndex]] = [
        updatedSections[targetIndex],
        updatedSections[index],
      ];
      setContent(updatedSections);
    }
  };

  const handleDeleteContent = async (contentIndex: number) => {
    if (content) {
      const updatedContent = content.filter((_, i) => i !== contentIndex);
      setContent(updatedContent);
      toast.success('Contenido eliminado correctamente');
    } else {
      toast.error('Contenido no disponible.');
    }
  };

  return (
    <section className="p-6">
      <ToastContainer />
      <h3 className="text-center text-lg font-semibold mb-6">Actualizar Sección</h3>
      <Button
        variant="Primary"
        onClick={handleUpdateSection}
        className="mb-6 w-full py-2 rounded-lg"
      >
        Subir Cambios
      </Button>

      <input
        type="text"
        placeholder="Nombre de la sección"
        className="bg-tecnofi-background font-medium border-[2px] border-tecnofi-primary rounded-[8px] w-full py-2 px-4 mb-2 focus:outline-none"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />

      {content && content.length > 0 && (
        <SectionForm
          onSave={handleSave}
          section={section}
          contentIndex={editingContentIndex}
        />
      )}

      <article>
        {section && (
          <div className="mb-6 border-[2px] border-tecnofi-primary p-6 rounded bg-tecnofi-background">
            <h4 className="font-medium text-lg mb-4">{section.name}</h4>

            <article>
              {content?.map((contentItem, index) => (
                <div key={index} className="mb-6 border-[2px] border-tecnofi-primary p-6 rounded bg-tecnofi-background">
                  <article className="flex justify-between items-center relative">
                    <h4 className="font-medium text-lg">{contentItem.name}</h4>
                    <span className="flex flex-col justify-center items-center gap-2">
                      <Button
                        variant="Primary"
                        className="text-[#FFF] rounded-lg p-[4px]"
                        onClick={() => handleDeleteContent(index)}
                      >
                        <TiFolderDelete size={26} fill="#FFF" />
                      </Button>
                      <Button
                        variant="Primary"
                        className="text-[#FFF] rounded-lg p-[4px]"
                        onClick={() => handleEdit(index)}
                      >
                        <TiEdit size={26} fill="#FFF" />
                      </Button>
                      <Button
                        variant="Primary"
                        className="text-[#FFF] rounded-lg p-[4px]"
                        onClick={() => handleMoveSection(index, 'up')}
                        disabled={index === 0}
                      >
                        <TiArrowUp size={26} fill="#FFF" />
                      </Button>
                      <Button
                        variant="Primary"
                        className="text-[#FFF] rounded-lg p-[4px]"
                        onClick={() => handleMoveSection(index, 'down')}
                        disabled={index === content.length - 1}
                      >
                        <TiArrowDown size={26} fill="#FFF" />
                      </Button>
                    </span>
                  </article>

                  <p className="text-sm mb-4">
                    {contentItem.isHighlighted ? 'Sección destacada' : 'Sección no destacada'}
                  </p>

                  <article className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {contentItem.contents?.map((content, j) => (
                      <div key={j} className="relative border-[2px] border-tecnofi-primary p-4 rounded bg-white shadow-sm">
                        {content.name && (
                          <p className="mb-2">
                            <span className="font-medium">Nombre del Contenido: </span>
                            {content.name}
                          </p>
                        )}
                        {content.image && (
                          <div className="mb-2">
                            <span className="font-medium">Imagen del Contenido: </span>
                            <img
                              src={content.image}
                              alt={content.name || 'Imagen del contenido'}
                              className="h-auto w-auto max-h-40 rounded border-[2px] border-tecnofi-primary mx-auto mb-2 object-cover"
                            />
                          </div>
                        )}
                        {content.url && (
                          <p className="mb-2">
                            <span className="font-medium">Link del Contenido: </span>
                            <Link to={content.url} className="text-tecnofi-primary line-clamp-1 underline">
                              {content.url}
                            </Link>
                          </p>
                        )}
                      </div>
                    ))}
                  </article>
                </div>
              ))}
            </article>
          </div>
        )}
      </article>
    </section>
  );
}

export default UpdateSections;