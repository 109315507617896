import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { MdOutlineAccountCircle, MdOutlineSpaceDashboard, MdShoppingCart, MdOutlineLightMode, MdOutlineDarkMode, MdLogout } from "react-icons/md";
import { PiStarBold } from "react-icons/pi";
import { RiFileList3Line, RiFileList3Fill } from "react-icons/ri";
import { Permission } from "../../../hooks/types";
import Button from "../../button/Button";
import useAuth from "../../../hooks/useAuth";


interface SettingsProps {
  toggleTheme: () => void;
  toggleLogin: () => void;
}

function Settings({ toggleTheme, toggleLogin }: SettingsProps) {
  const [theme, setTheme] = useState(false);
  const { isAuthenticated, logout, getUserInfo, hasPermission } = useAuth();
  const userInfo = getUserInfo();

  const isAdmin = hasPermission(
    Permission.ADMIN_BRANDS,
    Permission.ADMIN_CLIENTS,
    Permission.ADMIN_CONFIG,
    Permission.ADMIN_CONTENTS,
    Permission.ADMIN_IMAGES,
    Permission.ADMIN_ORDERS,
    Permission.ADMIN_PRODUCTS,
    Permission.ADMIN_REPORTS,
    Permission.ADMIN_ROLES,
    Permission.ADMIN_STOCK,
    Permission.ADMIN_TAGS
  );

  const toggleThemeHandler = () => {
    setTheme(!theme);
    toggleTheme();
  };

  return (
    <div className="flex absolute rounded-[8px] z-40 border-[3px] border-tecnofi-primary bg-tecnofi-background lg:max-w-[500px] max-h-[500px] max-w-[300px] px-[16px] py-[8px] right-0 top-[74px] overflow-auto">
      <ul>
        {!isAuthenticated() && (
          <li className="flex items-center focus:no-underline">
            <Button
              className="flex items-center text-tecnofi-text font-medium gap-2 my-[1px] hover:text-tecnofi-primary hover:underline"
              onClick={toggleLogin}
            >
              <span className="flex bg-tecnofi-primary rounded-lg p-1">
                <MdOutlineAccountCircle size={20} fill="#FFF" />
              </span>
              <p>Login</p>
            </Button>
          </li>
        )}
        {isAdmin && (
          <li className="flex items-center focus:no-underline">
            <Link to="/dashboard" className="flex items-center text-tecnofi-text font-medium gap-2 my-[1px] hover:text-tecnofi-primary hover:underline">
              <span className="flex bg-tecnofi-primary rounded-lg p-1">
                <MdOutlineSpaceDashboard size={20} fill="#FFF" />
              </span>
              <p>Dashboard</p>
            </Link>
          </li>
        )}
        <li className="flex items-center focus:no-underline">
          <Link to="/shopping-cart" className="flex items-center text-tecnofi-text font-medium gap-2 my-[1px] hover:text-tecnofi-primary hover:underline">
            <span className="flex bg-tecnofi-primary rounded-lg p-1">
              <MdShoppingCart size={20} fill="#FFF" />
            </span>
            <p>Carrito de Compras</p>
          </Link>
        </li>
        <li className="flex items-center focus:no-underline">
          <Link to="/wishlist" className="flex items-center text-tecnofi-text font-medium gap-2 my-[1px] hover:text-tecnofi-primary hover:underline">
            <span className="flex bg-tecnofi-primary rounded-lg p-1">
              <PiStarBold size={20} fill="#FFF" />
            </span>
            <p>Lista de Deseos</p>
          </Link>
        </li>
        {isAuthenticated() && (
          <>
            {userInfo?.id && (
              <li className="flex items-center focus:no-underline">
                <Link to={`/user/${userInfo.id}`} className="flex items-center text-tecnofi-text font-medium gap-2 my-[1px] hover:text-tecnofi-primary hover:underline">
                  <span className="flex bg-tecnofi-primary rounded-lg p-1">
                    <MdOutlineAccountCircle size={20} fill="#FFF" />
                  </span>
                  <p>Mi cuenta</p>
                </Link>
              </li>
            )}
            <li className="flex items-center focus:no-underline">
              <Link to="/orders" className="flex items-center text-tecnofi-text font-medium gap-2 my-[1px] hover:text-tecnofi-primary hover:underline">
                <span className="flex bg-tecnofi-primary rounded-lg p-1">
                  <RiFileList3Line size={20} fill="#FFF" />
                </span>
                <p>Ordenes</p>
              </Link>
            </li>
            <li className="flex items-center focus:no-underline">
              <Link to="/quotes" className="flex items-center text-tecnofi-text font-medium gap-2 my-[1px] hover:text-tecnofi-primary hover:underline">
                <span className="flex bg-tecnofi-primary rounded-lg p-1">
                  <RiFileList3Fill size={20} fill="#FFF" />
                </span>
                <p>Cotizaciones</p>
              </Link>
            </li>
          </>
        )}
        <li className="flex items-center focus:no-underline">
          <Button
            className="flex items-center text-tecnofi-text font-medium gap-2 my-[1px] hover:text-tecnofi-primary hover:underline"
            onClick={toggleThemeHandler}
          >
            <span className="flex bg-tecnofi-primary rounded-lg p-1">
              {theme ? <MdOutlineLightMode size={22} fill="#FFF" /> : <MdOutlineDarkMode size={20} fill="#FFF" />}
            </span>
            <p>{theme ? "Modo Claro" : "Modo Oscuro"}</p>
          </Button>
        </li>
        {isAuthenticated() && (
          <li className="flex items-center focus:no-underline">
            <Button
              className="flex items-center text-tecnofi-text font-medium gap-2 my-[1px] hover:text-tecnofi-primary hover:underline"
              onClick={logout}
            >
              <span className="flex bg-tecnofi-primary rounded-lg p-1">
                <MdLogout size={20} fill="#FFF" />
              </span>
              <p>Logout</p>
            </Button>
          </li>
        )}
      </ul>
    </div>
  );
}

export default Settings;
