import React from 'react';
import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router-dom';

function Contents() {
  return (
    <>
      <Helmet>
        <title>Tecnoficom | Dashboard Administrativo</title>
        <meta
          name="description"
          content="Gestiona de forma eficiente el contenido de tu tienda virtual con el dashboard administrativo de Tecnoficom. Controla productos, categorías, inventarios y más en una plataforma integral y fácil de usar."
        />
        <meta
          name="keywords"
          content="Dashboard administrativo, gestión de contenidos, tienda virtual, administración de productos, control de inventarios, gestión de categorías, plataforma de gestión, Tecnoficom, panel administrativo, administración eficiente"
        />
      </Helmet>
      <Outlet />
    </>
  );
};

export default Contents;