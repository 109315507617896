import React from 'react';
import { Helmet } from 'react-helmet';
import GetConfigurations from './component/GetConfigurations';

function Configurations() {
  return (
    <>
      <Helmet>
        <title>Tecnoficom | Configuraciones del Dashboard</title>
        <meta
          name="description"
          content="Gestiona y configura todos los aspectos del dashboard administrativo de Tecnoficom. Administra las opciones de visualización, usuarios, seguridad y más para una experiencia optimizada."
        />
        <meta
          name="keywords"
          content="Tecnoficom, dashboard administrativo, configuraciones del dashboard, administración de usuarios, opciones de visualización, configuración de seguridad, ajustes de interfaz, herramientas de administración, optimización de dashboard"
        />
      </Helmet>
      <GetConfigurations />
    </>
  );
};

export default Configurations;