import React, { useEffect, useState } from 'react';
import Wrapper from '../wrapper/Wrapper';
import { ContentSection, FooterProps, Section } from './types';
import { toast } from 'react-toastify';
import axios from 'axios';
import useAuth from '../../hooks/useAuth';
import { Link } from 'react-router-dom';

function Footer({ togglePolitics }: FooterProps) {
  const { authToken } = useAuth();
  const [footer, setFooter] = useState<ContentSection | null>(null);

  useEffect(() => {
    const fetchConfigurations = async () => {
      try {
        const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_TECNOFICOM}/contents`, { headers });
        if (response.data) {
          const footerSection = response.data.find((section: ContentSection) => section.name === "Footer");
          setFooter(footerSection);
        }
      } catch (error) {
        toast.error('Hubo un problema al cargar las configuraciones.');
      }
    };

    fetchConfigurations();
  }, [authToken]);

  const handlePolitics = () => {
    togglePolitics();
  };

  return (
    <footer className="bg-tecnofi-dark text-[#fff] pt-[8px] lg:pt-[22px] mt-auto">
      <Wrapper>
        <section className="grid md:grid-cols-2 grid-cols-1 gap-[32px] py-[20px]">
          {footer && footer.content?.map((section: Section, index: number) => (
            <article key={section.name} className="text-center">
              <h5 className="text-lg font-medium mb-4">{section.name}</h5>
              {section.contents?.map((item, idx) => (
                <React.Fragment key={idx}>
                  {item.url ? (
                    <Link
                      to={item.url}
                      className="flex flex-col justify-center items-center hover:underline cursor-pointer"
                    >
                      {item.name && (
                        <h6 className="font-medium text-sm mb-2">{item.name}</h6>
                      )}
                      {item.image && (
                        <img
                          src={item.image}
                          alt={item.name}
                          className="h-10 w-auto max-h-[120px] mx-auto mb-1"
                        />
                      )}
                    </Link>
                  ) : (
                    <div className="flex flex-col justify-start">
                      {item.name && (
                        <h6 className="font-medium text-sm mb-2">{item.name}</h6>
                      )}
                      {item.image && (
                        <img
                          src={item.image}
                          alt={item.name}
                          className="h-auto w-auto max-w-[120px] max-h-[120px] mx-auto"
                        />
                      )}
                    </div>
                  )}
                </React.Fragment>
              ))}
              {index === footer.content.length - 1 && (
                <div className="cursor-pointer hover:underline" onClick={handlePolitics}>
                  Politicas de Privacidad y Terminos y Condiciones
                </div>
              )}
            </article>
          ))}
        </section>
      </Wrapper>
      <p className="bg-tecnofi-primary text-center text-[10px] font-bold mt-auto py-[8px]">
        Copyright 2024 <span className="">Tecnoficom</span> | Diseñado por:
        <a href="https://www.tecnoficom.com"> Tecnoficom Ltda</a>
      </p>
    </footer>
  );
}

export default Footer;