import React from 'react';
import { MainHomeProps } from './types';
import { Wrapper } from '../../../component';
import { Link } from 'react-router-dom';

function LoginBanner({ setHome }: MainHomeProps) {
  const { content } = setHome;

  return (
    <>
      {content && (
        <section className="bg-tecnofi-primary text-tecnofi-background py-10">
          <Wrapper>
            {content.map((section, index) => (
              <div key={index} className="grid md:grid-cols-2 mb-8">
                {section.contents && section.contents[0]?.image && (
                  <img
                    src={section.contents[0].image}
                    alt={section.contents[0].name}
                    className="hidden md:flex text-center rounded-lg h-[400px] mx-auto my-auto"
                  />
                )}
                <article className="flex flex-col justify-center items-center">
                  <h4 className="font-semibold text-4xl text-center mb-4">
                    {section.name}
                  </h4>
                  {section.contents &&
                    section.contents.map((item) => (
                      <div key={item.name} className="flex flex-col items-center">
                        <p className="text-center mb-2">{item.name}</p>
                        <Link
                          to={`${item.url}`}
                          className="flex w-auto bg-tecnofi-secondary text-[#fff] font-medium rounded-lg text-xl px-5 py-3 mt-5 mx-auto"
                        >
                          Accede
                        </Link>
                      </div>
                    ))}
                </article>
              </div>
            ))}
          </Wrapper>
        </section>
      )}
    </>
  );
}

export default LoginBanner;
