import axios from "axios";
import { useState, useEffect } from "react";
import { AppConfig, ThemeColors } from "../pages/Configurations/component/types";

const useFetchTheme = (initialDarkMode?: boolean) => {
  const defaultTheme: AppConfig = {
    icon: '/favicon.ico',
    logo: '/tecnoficom-logo.png',
    email: { user: '' },
    taxes: { reteica: 0, retefuente: 0 },
    theme: {
      light: {
        primary: '#188f4b',
        secondary: '#70c031',
        dark: '#17202B',
        text: '#313532',
        fadedText: '#B0B7BD',
        background: '#F9F9F9',
        section: '#EEE'
      },
      dark: {
        primary: '#41a114',
        secondary: '#0E6631',
        dark: '#0B1219',
        text: '#d0d6dd',
        fadedText: '#90979D',
        background: '#1C1C1C',
        section: '#333'
      }
    },
    extraPercentage: {
      GOVERMENT: 0,
      TECHNICAL: 0,
      DISTRIBUTOR: 0,
      CLIENT_FINAL: 0,
      SOLUTION_INTEGRATOR: 0,
    },
    minimumPurchase: 0,
    retentionThreshold: 0
  };

  const [themeConfig, setThemeConfig] = useState<AppConfig>(defaultTheme);
  const [isDarkMode, setIsDarkMode] = useState(initialDarkMode);

  const setThemeVariables = (themeColors: ThemeColors) => {
    if (!themeColors) return;
  
    const root = document.documentElement;
    root.style.setProperty('--tecnofi-primary', themeColors.primary);
    root.style.setProperty('--tecnofi-secondary', themeColors.secondary);
    root.style.setProperty('--tecnofi-dark', themeColors.dark);
    root.style.setProperty('--tecnofi-text', themeColors.text);
    root.style.setProperty('--tecnofi-faded-text', themeColors.fadedText);
    root.style.setProperty('--tecnofi-background', themeColors.background);
    root.style.setProperty('--tecnofi-section', themeColors.section);
  };

  useEffect(() => {
    setIsDarkMode(initialDarkMode);
  }, [initialDarkMode]);

  useEffect(() => {
    const root = document.documentElement;
  
    if (isDarkMode) {
      root.setAttribute('data-theme', 'dark');
    } else {
      root.removeAttribute('data-theme');
    }
  
    const themeColors = isDarkMode ? themeConfig?.theme.dark : themeConfig?.theme.light;
    if (themeColors) {
      setThemeVariables(themeColors);
    }
  }, [isDarkMode, themeConfig]);

  useEffect(() => {
    const fetchTheme = async () => {
      try {
        const response = await axios.get<AppConfig>(`${process.env.REACT_APP_BACKEND_TECNOFICOM}/config`);
        const newTheme = response.data;
  
        setThemeConfig(newTheme);
  
        const themeColors = isDarkMode ? newTheme.theme.dark : newTheme.theme.light;
        setThemeVariables(themeColors);
  
        const favicon = document.getElementById("dynamic-favicon") as HTMLLinkElement | null;
        if (favicon) favicon.href = newTheme.icon;
      } catch (error) {
        console.error('Error fetching theme, applying default', error);
        setThemeConfig(defaultTheme);
        const themeColors = isDarkMode ? defaultTheme.theme.dark : defaultTheme.theme.light;
        setThemeVariables(themeColors);
  
        const favicon = document.getElementById("dynamic-favicon") as HTMLLinkElement | null;
        if (favicon) favicon.href = defaultTheme.icon;
      }
    };
  
    fetchTheme();
  }, []);

  const toggleTheme = () => {
    setIsDarkMode(prevMode => !prevMode);
  };

  return { ...themeConfig, isDarkMode, toggleTheme };
};

export default useFetchTheme;
