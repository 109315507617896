import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import { Permission } from '../../../hooks/types';

interface DashboardAsideProps {
  isVisible: boolean;
  closeAside: () => void;
}

const DashboardAside = ({ isVisible, closeAside }: DashboardAsideProps) => {
  const { hasPermission } = useAuth();

  const permissions = useMemo(() => ({
    adminClients: hasPermission(Permission.ADMIN_CLIENTS),
    adminProducts: hasPermission(Permission.ADMIN_PRODUCTS),
    adminOrders: hasPermission(Permission.ADMIN_ORDERS),
    adminContents: hasPermission(Permission.ADMIN_CONTENTS),
    adminRoles: hasPermission(Permission.ADMIN_ROLES),
    adminStock: hasPermission(Permission.ADMIN_STOCK),
    adminImages: hasPermission(Permission.ADMIN_IMAGES),
    adminTags: hasPermission(Permission.ADMIN_TAGS),
    adminBrands: hasPermission(Permission.ADMIN_BRANDS),
    adminReports: hasPermission(Permission.ADMIN_REPORTS),
    adminConfig: hasPermission(Permission.ADMIN_CONFIG),
  }), [hasPermission]);

  return (
    <aside
      className={`bg-tecnofi-section text-tecnofi-text transition-all duration-300 ease-in-out absolute lg:h-auto lg:flex lg:w-[16%] ${
        isVisible ? 'w-[70%] h-full z-50' : 'hidden w-0'
      } lg:static`}
    >
      <nav className="max-w-full px-4 py-3">
        <h3 className="text-center text-lg font-semibold mb-2">Opciones</h3>
        <ul>
          {permissions.adminContents && (
            <li className="hover:text-tecnofi-primary cursor-pointer mb-2">
              <Link to="/dashboard/contents" onClick={closeAside}>Contenidos</Link>
            </li>
          )}
          {permissions.adminClients && (
            <li className="hover:text-tecnofi-primary cursor-pointer mb-2">
              <Link to="/dashboard/clients" onClick={closeAside}>Clientes</Link>
            </li>
          )}
          {permissions.adminRoles && (
            <li className="hover:text-tecnofi-primary cursor-pointer mb-2">
              <Link to="/dashboard/roles" onClick={closeAside}>Roles</Link>
            </li>
          )}
          {permissions.adminProducts && (
            <li className="hover:text-tecnofi-primary cursor-pointer mb-2">
              <Link to="/dashboard/admin-products" onClick={closeAside}>Productos</Link>
            </li>
          )}
          {permissions.adminStock && (
            <li className="hover:text-tecnofi-primary cursor-pointer mb-2">
              <Link to="/dashboard/admin-products/stock" onClick={closeAside}>Monitorear Stock</Link>
            </li>
          )}
          {permissions.adminImages && (
            <li className="hover:text-tecnofi-primary cursor-pointer mb-2">
              <Link to="/dashboard/admin-products/images" onClick={closeAside}>Imágenes</Link>
            </li>
          )}
          {permissions.adminTags && (
            <li className="hover:text-tecnofi-primary cursor-pointer mb-2">
              <Link to="/dashboard/admin-products/categories" onClick={closeAside}>Categorías</Link>
            </li>
          )}
          {permissions.adminBrands && (
            <li className="hover:text-tecnofi-primary cursor-pointer mb-2">
              <Link to="/dashboard/admin-products/brands" onClick={closeAside}>Marcas</Link>
            </li>
          )}
          {permissions.adminOrders && (
            <li className="hover:text-tecnofi-primary cursor-pointer mb-2">
              <Link to="/dashboard/history" onClick={closeAside}>Órdenes y Cotizaciones</Link>
            </li>
          )}
          {permissions.adminReports && (
            <li className="hover:text-tecnofi-primary cursor-pointer mb-2">
              <Link to="/dashboard/reports" onClick={closeAside}>Reportes</Link>
            </li>
          )}
          {permissions.adminConfig && (
            <li className="hover:text-tecnofi-primary cursor-pointer mb-2">
              <Link to="/dashboard/config" onClick={closeAside}>Configuraciones</Link>
            </li>
          )}
        </ul>
      </nav>
    </aside>
  );
};

export default DashboardAside;
