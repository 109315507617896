import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { DashboardAside, DashboardHeader } from '../component/dashboard';
import { FaAngleRight, FaAngleLeft } from 'react-icons/fa';

export interface DashboardProps {
  toggleTheme: () => void;
  toggleLogin: () => void;
}

function DashboardLayout({ toggleTheme, toggleLogin }: DashboardProps) {
  const [isAsideVisible, setIsAsideVisible] = useState(false);

  const toggleAside = () => {
    setIsAsideVisible(!isAsideVisible);
  };

  return (
    <div className="flex flex-col min-h-screen">
      <DashboardHeader toggleTheme={toggleTheme} toggleLogin={toggleLogin} />
      <div className="flex flex-1 max-w-full bg-tecnofi-background relative">
        <button
          onClick={toggleAside}
          className={`lg:hidden rounded-tr-lg rounded-br-lg px-1 py-3 bg-tecnofi-faded-text text-white fixed top-1/2 z-50 transition-all duration-300 ${isAsideVisible ? 'left-[70%]' : 'left-0'}`}
        >
          {isAsideVisible ? (
            <FaAngleLeft size="16" fill="#fff" />
          ) : (
            <FaAngleRight size="16" fill="#fff" />
          )}
        </button>

        <DashboardAside isVisible={isAsideVisible} closeAside={toggleAside} />
        <main className="flex-1 lg:w-[82%] w-full pb-10">
          <Outlet />
        </main>
      </div>
    </div>
  );
}

export default DashboardLayout;
