import { Link } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import Button from "../../button/Button";
import LogoNavbar from "../../header/component/LogoNavbar";
import Wrapper from "../../wrapper/Wrapper";
import { MdHome, MdLogout, MdOutlineAccountCircle } from "react-icons/md";
import { IoMdSettings } from "react-icons/io";
import { Settings } from "../../header/component";
import { useState } from "react";
import { HeaderProps } from "../../header/types";

export interface DashboardHeaderProps {
  toggleTheme: () => void;
  toggleLogin: () => void;
}

function DashboardHeader({ toggleTheme, toggleLogin }: DashboardHeaderProps) {
  const { getUserInfo } = useAuth();
  const [showSettings, setShowSettings] = useState(false);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
  };

  return (
    <header className="bg-tecnofi-section border-b-[3px] border-tecnofi-primary">
      <Wrapper>
        <div className="flex relative justify-between items-center gap-[8px] py-[8px]">
          <LogoNavbar className="h-10 w-auto" />
          <div className="flex items-center gap-2">
            <Link to={"/"} className="flex bg-tecnofi-primary active:bg-tecnofi-secondary text-[#fff] my-auto rounded-[8px] px-[4px] py-[4px]">
              <MdHome size={26} fill="#FFF" />
            </Link>
            <Button type="button" variant="Primary" className="flex relative rounded-[8px] px-[6px] py-[4px]" onClick={toggleSettings}>
              <IoMdSettings size={26} color="white" />
            </Button>
            {showSettings && <Settings toggleTheme={toggleTheme} toggleLogin={toggleLogin} />}
          </div>
        </div>
      </Wrapper>
    </header>
  )
}

export default DashboardHeader;