import React, { useEffect, useState } from 'react';
import { Button, Wrapper } from '../../../component';
import useAuth from '../../../hooks/useAuth';
import axios from 'axios';
import { MdOutlineCreate, MdOutlineEdit } from 'react-icons/md';
import { IoMdTrash } from 'react-icons/io';
import { Permission } from '../../../hooks/types';
import { toast } from 'react-toastify';

export interface Role {
  id: string;
  name: string;
  description: string;
  permissions: Permission[];
}

function GetRoles() {
  const { authToken } = useAuth();
  const [roles, setRoles] = useState<Role[]>([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [roleToUpdate, setRoleToUpdate] = useState<Role | null>(null);
  const [newRoleName, setNewRoleName] = useState('');
  const [newRoleDescription, setNewRoleDescription] = useState('');
  const [newRolePermissions, setNewRolePermissions] = useState<Permission[]>([]);
  const [roleToDelete, setRoleToDelete] = useState<string | null>(null);

  const allPermissions = Object.values(Permission);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
        const response = await axios.get<Role[]>(
          `${process.env.REACT_APP_BACKEND_TECNOFICOM}/roles`,
          { headers }
        );

        setRoles(Array.isArray(response.data) ? response.data : []);
      } catch (error) {
        console.error('Error fetching roles:', error);
        setRoles([]);
      }
    };

    fetchRoles();
  }, [authToken]);

  const handleDeleteClick = (id: string) => {
    setRoleToDelete(id);
    setShowConfirmModal(true);
  };

  const deleteRole = async () => {
    if (!roleToDelete) return;
    try {
      const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
      await axios.delete(`${process.env.REACT_APP_BACKEND_TECNOFICOM}/roles/${roleToDelete}`, { headers });
      setRoles(roles.filter((role) => role.id !== roleToDelete));
      setShowConfirmModal(false);
    } catch (error) {
      console.error('Error deleting role:', error);
    }
  };

  const handleCreateRole = async () => {
    if (!newRoleName.trim()) return;
    try {
      const payload = {
        name: newRoleName,
        description: newRoleDescription,
        permissions: newRolePermissions,
      };
      const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_TECNOFICOM}/roles`, payload, { headers });
      setRoles([...roles, response.data]);
      setShowCreateModal(false);
      setNewRoleName('');
      setNewRoleDescription('');
      setNewRolePermissions([]);
    } catch (error) {
      toast.error('Error creando el rol');
    }
  };

  const handleUpdateClick = (role: Role) => {
    setRoleToUpdate(role);
    setNewRoleName(role.name);
    setNewRoleDescription(role.description);
    setNewRolePermissions(role.permissions);
    setShowUpdateModal(true);
  };

  const handleUpdateRole = async () => {
    if (!roleToUpdate) return;
    try {
      const updatedRole = {
        name: newRoleName,
        description: newRoleDescription,
        permissions: newRolePermissions,
      };
      const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
      await axios.patch(
        `${process.env.REACT_APP_BACKEND_TECNOFICOM}/roles/${roleToUpdate.id}`,
        updatedRole,
        { headers }
      );
      setRoles((prevRoles) =>
        prevRoles.map((role) =>
          role.id === roleToUpdate.id ? { ...role, ...updatedRole } : role
        )
      );
      setShowUpdateModal(false);
      setRoleToUpdate(null);
      setNewRoleName('');
      setNewRoleDescription('');
      setNewRolePermissions([]);
  
      toast.success("Rol actualizado correctamente");
    } catch (error) {
      toast.error("Error actualizando el rol");
    }
  };

  return (
    <Wrapper>
      {showConfirmModal && (
        <section className="fixed z-50 inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <article className="flex flex-col bg-tecnofi-background p-4 rounded-lg shadow-md w-full max-w-md mx-4">
            <h3 className="text-tecnofi-text text-lg font-medium mb-4">
              ¿Estás seguro de que quieres eliminar este rol?
            </h3>
            <span className="flex justify-between">
              <Button className="rounded px-4 py-2" onClick={() => setShowConfirmModal(false)}>
                Cancelar
              </Button>
              <Button variant="Primary" className="rounded px-4 py-2" onClick={deleteRole}>
                Confirmar
              </Button>
            </span>
          </article>
        </section>
      )}

      {/* Modal de creación */}
      {showCreateModal && (
        <section className="fixed z-50 inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <article className="flex flex-col bg-tecnofi-background p-4 rounded-lg shadow-md w-full max-w-md mx-4">
            <h3 className="text-tecnofi-text text-lg font-medium mb-4">Crear nuevo rol</h3>
            <input
              type="text"
              value={newRoleName}
              onChange={(e) => setNewRoleName(e.target.value)}
              placeholder="Nombre del rol"
              className="border-[2px] border-tecnofi-primary rounded-[8px] text-tecnofi-text w-full px-4 h-[52px] bg-tecnofi-section placeholder-tecnofi-faded-text focus:outline-none"
            />
            <textarea
              value={newRoleDescription}
              onChange={(e) => setNewRoleDescription(e.target.value)}
              placeholder="Descripción del rol"
              className="border-[2px] border-tecnofi-primary rounded-[8px] text-tecnofi-text w-full py-2 px-4 h-[80px] bg-tecnofi-section placeholder-tecnofi-faded-text focus:outline-none mt-4"
            />
            <div className="mt-4">
              <p className="text-tecnofi-text font-medium mb-2">Permisos:</p>
              <div className="grid grid-cols-2 gap-2">
                {allPermissions.map((permission) => (
                  <div key={permission} className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      id={permission}
                      value={permission}
                      checked={newRolePermissions.includes(permission)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setNewRolePermissions([...newRolePermissions, permission]);
                        } else {
                          setNewRolePermissions(newRolePermissions.filter((p) => p !== permission));
                        }
                      }}
                      className="h-5 w-5 accent-tecnofi-primary border border-tecnofi-primary rounded-sm transition duration-300 ease-in-out"
                    />
                    <label htmlFor={permission} className="text-tecnofi-text">{permission}</label>
                  </div>
                ))}
              </div>
            </div>
            <span className="flex justify-between mt-4">
              <Button className="rounded px-4 py-2" onClick={() => setShowCreateModal(false)}>
                Cancelar
              </Button>
              <Button variant="Primary" className="rounded px-4 py-2" onClick={handleCreateRole}>
                Crear
              </Button>
            </span>
          </article>
        </section>
      )}

      {/* Modal de actualización */}
      {showUpdateModal && (
        <section className="fixed z-50 inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <article className="flex flex-col bg-tecnofi-background p-4 rounded-lg shadow-md w-full max-w-md mx-4">
            <h3 className="text-tecnofi-text text-lg font-medium mb-4">Actualizar rol</h3>
            <input
              type="text"
              value={newRoleName}
              onChange={(e) => setNewRoleName(e.target.value)}
              placeholder="Nombre del rol"
              className="border-[2px] border-tecnofi-primary rounded-[8px] text-tecnofi-text w-full px-4 h-[52px] bg-tecnofi-section placeholder-tecnofi-faded-text focus:outline-none"
            />
            <textarea
              value={newRoleDescription}
              onChange={(e) => setNewRoleDescription(e.target.value)}
              placeholder="Descripción del rol"
              className="border-[2px] border-tecnofi-primary rounded-[8px] text-tecnofi-text w-full py-2 px-4 h-[80px] bg-tecnofi-section placeholder-tecnofi-faded-text focus:outline-none mt-4"
            />
            <div className="mt-4">
              <p className="text-tecnofi-text font-medium mb-2">Permisos:</p>
              <div className="grid grid-cols-2 gap-2">
                {allPermissions.map((permission) => (
                  <div key={permission} className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      id={`update-${permission}`}
                      value={permission}
                      checked={newRolePermissions.includes(permission)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setNewRolePermissions([...newRolePermissions, permission]);
                        } else {
                          setNewRolePermissions(newRolePermissions.filter((p) => p !== permission));
                        }
                      }}
                      className="h-5 w-5 accent-tecnofi-primary border border-tecnofi-primary rounded-sm transition duration-300 ease-in-out"
                    />
                    <label htmlFor={`update-${permission}`} className="text-tecnofi-text">{permission}</label>
                  </div>
                ))}
              </div>
            </div>
            <span className="flex justify-between mt-4">
              <Button className="rounded px-4 py-2" onClick={() => setShowUpdateModal(false)}>
                Cancelar
              </Button>
              <Button variant="Primary" className="rounded px-4 py-2" onClick={handleUpdateRole}>
                Actualizar
              </Button>
            </span>
          </article>
        </section>
      )}

      {/* Sección principal */}
      <section>
        <Button
          variant="Primary"
          className="flex justify-center items-center rounded-lg gap-2 px-4 py-2 my-4"
          onClick={() => setShowCreateModal(true)}
        >
          <MdOutlineCreate size={16} /> Crear Rol
        </Button>
        <article>
          <div className="overflow-x-auto">
            <table className="min-w-full border-separate border-spacing-0">
              <thead>
                <tr className="bg-tecnofi-background text-tecnofi-text text-sm font-medium">
                  <th className="px-4 py-2 border-b-2 border-tecnofi-border">Nombre</th>
                  <th className="px-4 py-2 border-b-2 border-tecnofi-border">Descripción</th>
                  <th className="px-4 py-2 border-b-2 border-tecnofi-border">Permisos</th>
                  <th className="px-4 py-2 border-b-2 border-tecnofi-border">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(roles) && roles.length > 0 ? (
                  roles.map((role) => (
                    <tr key={role.id} className="border-b-[1px] border-tecnofi-border">
                      <td className="px-4 py-2">{role.name}</td>
                      <td className="px-4 py-2">{role.description}</td>
                      <td className="px-4 py-2">
                        <ul>
                          {role.permissions?.map((permission, index) => (
                            <li key={index}>{permission}</li>
                          ))}
                        </ul>
                      </td>
                      <td className="px-4 py-2 text-center flex justify-center gap-2">
                        <Button
                          variant="Primary"
                          className="flex justify-center items-center rounded-[4px] px-[4px] py-[4px]"
                          onClick={() => handleUpdateClick(role)}
                        >
                          <MdOutlineEdit size={16} fill="#FFF" />
                        </Button>
                        <Button
                          variant="Primary"
                          className="flex justify-center items-center rounded-[4px] px-[4px] py-[4px]"
                          onClick={() => handleDeleteClick(role.id)}
                        >
                          <IoMdTrash size={16} fill="#FFF" />
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4} className="text-center py-4">No hay roles disponibles</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </article>
      </section>
    </Wrapper>
  );
}

export default GetRoles;
