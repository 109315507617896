import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { PaginatedUsers, User } from './types';
import { useNavigate } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { Button } from '../../../component';
import useAuth from '../../../hooks/useAuth';

function AllUsers() {
  const { authToken } = useAuth();
  const [users, setUsers] = useState<User[]>([]);
  const [pagina, setPagina] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState<number>(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get<PaginatedUsers>(`${process.env.REACT_APP_BACKEND_TECNOFICOM}/auth`, {
          headers: {
            Authorization: `Bearer ${authToken}`
          },
          params: { offset: pagina, limit: 40 },
        });
        setUsers(response.data.users);
        setTotalPaginas(Math.ceil(response.data.totalPages / limit));
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [pagina, limit]);

  const navigate = useNavigate();

  const handleLink = (id: string) => {
    return () => {
      navigate(`/dashboard/clients/${id}`);
    }
  };

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    };
    return date.toLocaleDateString('es-ES', options);
  };

  const getStatusClass = (status: string) => {
    switch (status) {
      case "ACTIVE":
        return "bg-[#93ff7255]";
      case "INACTIVE":
        return "bg-[#ff727255]";
      case "PENDING":
        return "bg-[#ffd97255]";
      default:
        return "";
    }
  };

  const handlePreviousPage = () => {
    if (pagina > 1) setPagina(pagina - 1);
  };

  const handleNextPage = () => {
    if (pagina < totalPaginas) setPagina(pagina + 1);
  };

  return (
    <section className="p-4">
      <h1 className="text-center text-2xl font-bold text-tecnofi-text px-8 py-4">
        Listado de Clientes
      </h1>
      <article className="w-full max-w-full overflow-x-scroll pb-4">
        <div className="flex justify-between items-center my-4">
          <Button variant="Primary" onClick={handlePreviousPage} disabled={pagina === 1} className="p-2 bg-gray-200 rounded-lg hover:bg-gray-300 disabled:bg-gray-100">
            <FaAngleLeft />
          </Button>
          <p className="text-tecnofi-text">Página {pagina} de {totalPaginas}</p>
          <Button variant="Primary" onClick={handleNextPage} disabled={pagina === totalPaginas} className="p-2 bg-gray-200 rounded-lg hover:bg-gray-300 disabled:bg-gray-100">
            <FaAngleRight />
          </Button>
        </div>
        <div className="min-w-full">
          <table className="w-[100px] divide-y">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-4 py-2 text-left text-xs font-medium text-tecnofi-text uppercase">Email</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-tecnofi-text uppercase">Client Roles</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-tecnofi-text uppercase">Client Status</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-tecnofi-text uppercase">Legal Person Type</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-tecnofi-text uppercase">Client Type</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-tecnofi-text uppercase">Full Name</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-tecnofi-text uppercase">Identification Number</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-tecnofi-text uppercase">Contact Phone</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-tecnofi-text uppercase">URL RUT</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-tecnofi-text uppercase">Position</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-tecnofi-text uppercase">Primary Address</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-tecnofi-text uppercase">Secondary Address</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-tecnofi-text uppercase">Business Name</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-tecnofi-text uppercase">Billing Email</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-tecnofi-text uppercase">Created At</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-tecnofi-text uppercase">Updated At</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-tecnofi-text uppercase">Data Treatment Authorization</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-tecnofi-text uppercase">Notification Authorization</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-tecnofi-text uppercase">Municipality</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-tecnofi-text uppercase">Comentarios</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {users.length > 0 && (
                users.map((user) => (
                  <tr key={user.id} onClick={handleLink(user.id)} className="cursor-pointer hover:bg-tecnofi-section">
                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-tecnofi-text">{user.email ?? 'N/A'}</td>
                    <td className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text">
                      {(user.roles ?? []).map(role => role.name).join(', ') || 'N/A'}
                    </td>
                    <td className={`px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text ${getStatusClass(user.clientStatus)}`}>{user.clientStatus ?? 'N/A'}</td>
                    <td className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text">{user.legalPersonType ? 'Yes' : 'No'}</td>
                    <td className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text">{user.clientType ?? 'N/A'}</td>
                    <td className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text">{user.profile?.fullName ?? 'N/A'}</td>
                    <td className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text">{user.profile?.identificationNumber ?? 'N/A'}</td>
                    <td className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text">{user.profile?.contactPhone ?? 'N/A'}</td>
                    <td className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text">{user.profile?.urlRUT ?? 'N/A'}</td>
                    <td className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text">{user.profile?.position ?? 'N/A'}</td>
                    <td className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text">{user.profile?.primaryAddress ?? 'N/A'}</td>
                    <td className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text">{user.profile?.secondaryAddress ?? 'N/A'}</td>
                    <td className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text">{user.profile?.businessName ?? 'N/A'}</td>
                    <td className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text">{user.profile?.billingEmail ?? 'N/A'}</td>
                    <td className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text">{user.profile?.createdAt ? formatDate(user.profile.createdAt) : 'N/A'}</td>
                    <td className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text">{user.profile?.updatedAt ? formatDate(user.profile.updatedAt) : 'N/A'}</td>
                    <td className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text">{user.profile?.dataTreatmentAuthorization ? 'Yes' : 'No'}</td>
                    <td className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text">{user.profile?.notificationAuthorization ? 'Yes' : 'No'}</td>
                    <td className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text">{user.profile?.location?.municipality ?? 'N/A'}</td>
                    <td className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text">{user.comments ?? 'N/A'}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </article>
    </section>
  );
}

export default AllUsers;
