export interface Role {
  id: string;
  name: string;
  description: string;
  permissions: Permission[];
}

export interface JwtPayload {
  id: string;
  email: string;
  legalPersonType: boolean;
  clientStatus: string;
  clientType: string;
  profileId: string;
  roles: Role[];
}

export enum Permission {
  USER = 'USER',
  ADMIN_CLIENTS = 'ADMIN_CLIENTS',
  ADMIN_PRODUCTS = 'ADMIN_PRODUCTS',
  ADMIN_ORDERS = 'ADMIN_ORDERS',
  ADMIN_CONTENTS = 'ADMIN_CONTENTS',
  ADMIN_ROLES = 'ADMIN_ROLES',
  ADMIN_STOCK = 'ADMIN_STOCK',
  ADMIN_IMAGES = 'ADMIN_IMAGES',
  ADMIN_TAGS = 'ADMIN_TAGS',
  ADMIN_BRANDS = 'ADMIN_BRANDS',
  ADMIN_REPORTS = 'ADMIN_REPORTS',
  ADMIN_CONFIG = 'ADMIN_CONFIG',
}