import React, { useState, useEffect } from 'react';
import { ContentFormProps, ContentItem } from './types';
import { Button } from '../../../component';

function ContentForm({
  onSave,
  content = [],
  contentItemIndex = null
}: ContentFormProps) {
  const [name, setName] = useState('');
  const [image, setImage] = useState('');
  const [url, setUrl] = useState('');

  useEffect(() => {
    if (Array.isArray(content) && contentItemIndex !== null) {
      const selectedContent = content[contentItemIndex];

      if (selectedContent) {
        setName(selectedContent.name || '');
        setImage(selectedContent.image || '');
        setUrl(selectedContent.url || '');
      }
    }
  }, [content, contentItemIndex]);

  const handleSave = () => {
    const contentData: ContentItem = { name, image, url };
    onSave(contentData, contentItemIndex);
    setName('');
    setImage('');
    setUrl('');
  };

  return (
    <div className="mb-4 border-[2px] border-tecnofi-primary p-4 rounded-lg bg-tecnofi-background">
      <input
        type="text"
        placeholder="Nombre del contenido"
        className="bg-tecnofi-background font-medium border-[2px] border-tecnofi-primary rounded-[8px] w-full py-2 px-4 mb-2 focus:outline-none"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <input
        type="text"
        placeholder="URL de la imagen"
        className="bg-tecnofi-background font-medium border-[2px] border-tecnofi-primary rounded-[8px] w-full py-2 px-4 mb-2 focus:outline-none"
        value={image}
        onChange={(e) => setImage(e.target.value)}
      />
      <input
        type="text"
        placeholder="URL del contenido"
        className="bg-tecnofi-background font-medium border-[2px] border-tecnofi-primary rounded-[8px] w-full py-2 px-4 mb-2 focus:outline-none"
        value={url}
        onChange={(e) => setUrl(e.target.value)}
      />
      <Button
        variant="Primary"
        onClick={handleSave}
        className="px-4 py-2 rounded-lg"
      >
        {contentItemIndex !== null ? 'Guardar Cambios' : 'Crear Contenido'}
      </Button>
    </div>
  );
}

export default ContentForm;