import React from 'react';
import { Helmet } from 'react-helmet';
import { ReportClients, ReportSales, ReportStock } from './component';
import { Wrapper } from '../../component';

function Reports() {
  return (
    <>
      <Helmet>
        <title>Tecnoficom | Reportes</title>
        <meta
          name="description"
          content="Accede y gestiona reportes detallados para la administración interna de Tecnoficom. Facilita el análisis de datos con herramientas de descarga y visualización optimizadas."
        />
        <meta
          name="keywords"
          content="Tecnoficom, reportes administrativos, gestión interna, análisis de datos, herramientas de reportes, administración empresarial, descarga de reportes, visualización de datos, reportes detallados, soluciones empresariales"
        />
      </Helmet>
      <Wrapper>
        <h3 className="text-tecnofi-text text-lg font-semibold text-center mt-6 mb-6">
          Reportes
        </h3>
        <ReportSales />
        <ReportClients />
        <ReportStock />
      </Wrapper>
    </>
  );
};

export default Reports;
