import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { OneUser, Role } from './types';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../../component';
import useAuth from '../../../hooks/useAuth';
import { toast, ToastContainer } from 'react-toastify';

function User() {
  const { id } = useParams<{ id: string }>();
  const { authToken } = useAuth();

  const [users, setUsers] = useState<OneUser | null>(null);
  const [roles, setRoles] = useState<Role[]>([]);

  const [openDropdown, setOpenDropdown] = useState<string | null>(null);

  const [selectedRoles, setSelectedRoles] = useState<Role[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
  const [selectedClientType, setSelectedClientType] = useState<string | null>(null);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showRolesModal, setShowRolesModal] = useState(false);

  const [reason, setReason] = useState('');
  const [pendingChange, setPendingChange] = useState<{
    id: string;
    type: 'status' | 'role' | 'clientType';
    newValue: string;
  } | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
        const user = await axios.get<OneUser>(`${process.env.REACT_APP_BACKEND_TECNOFICOM}/auth/${id}`, { headers });
        const roles = await axios.get<Role[]>(`${process.env.REACT_APP_BACKEND_TECNOFICOM}/roles`, { headers });
        setUsers(user.data);
        setRoles(roles.data);
        setSelectedStatus(user.data.getUser.clientStatus);
        setSelectedClientType(user.data.getUser.clientType);
        setSelectedRoles(user.data.getUser.roles);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [id, authToken]);

  const confirmChange = async (type: string) => {
    if (!pendingChange || !reason.trim()) {
      toast.error(`No se ha podido realizar el cambio porque no se llenó alguno de los campos.`);
      return;
    }
    try {
      const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
      const { id, newValue } = pendingChange;
      let payload = {};
      switch (type) {
        case 'status':
          payload = { clientStatus: newValue, comments: reason };
          break;
        case 'clientType':
          payload = { clientType: newValue, comments: reason };
          break;
      }
      await axios.patch(`${process.env.REACT_APP_BACKEND_TECNOFICOM}/auth/update/${id}`, payload, { headers });
      if (type === 'status') setSelectedStatus(newValue);
      if (type === 'clientType') setSelectedClientType(newValue);
      setShowConfirmModal(false);
      setReason('');
      setPendingChange(null);
      toast.success(`Se ha hecho el cambio de ${type}`);
    } catch (error) {
      console.error('Error al actualizar:', error);
      toast.error('Hubo un error al realizar el cambio.');
    }
  };

  const toggleDropdown = (dropdown: string) => {
    setOpenDropdown(openDropdown === dropdown ? null : dropdown);
  };

  const handleRoleChange = (roleId: string) => {
    const roleObj = roles.find(role => role.id === roleId);
    if (!roleObj) return;
    setSelectedRoles(prevRoles => {
      if (prevRoles.some(r => r.id === roleId)) {
        return prevRoles.filter(r => r.id !== roleId);
      } else {
        return [...prevRoles, roleObj];
      }
    });
  };


  const saveRoles = async () => {
    if (!reason.trim()) {
      toast.error('Debe proporcionar una razón para el cambio de roles.');
      return;
    }
    try {
      const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
      await axios.patch(
        `${process.env.REACT_APP_BACKEND_TECNOFICOM}/auth/update/${id}`,
        { roles: selectedRoles, comments: reason },
        { headers }
      );
      toast.success('Roles actualizados correctamente');
      setShowRolesModal(false);
    } catch (error) {
      console.error('Error al actualizar roles:', error);
      toast.error('Hubo un error al actualizar los roles.');
    }
  };


  const updateStatus = (userId: string, status: string, dropdown: string) => {
    if (status === selectedStatus) return;
    setPendingChange({ id: userId, type: 'status', newValue: status });
    setOpenDropdown(openDropdown === dropdown ? null : dropdown);
    setShowConfirmModal(true);
  };

  const updateClientType = (userId: string, type: string, dropdown: string) => {
    if (type === selectedClientType) return;
    setPendingChange({ id: userId, type: 'clientType', newValue: type });
    setOpenDropdown(openDropdown === dropdown ? null : dropdown);
    setShowConfirmModal(true);
  };

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    };
    return date.toLocaleDateString('es-ES', options);
  };

  return (
    <section className="p-4">
      <ToastContainer />
      {showConfirmModal && pendingChange && (
        <div className="fixed z-50 inset-0 bg-[#4444] flex justify-center items-center">
          <div className="bg-tecnofi-background p-4 rounded-lg shadow-md">
            <h2 className="text-tecnofi-primary text-lg font-bold mb-4">Confirmación</h2>
            <p>
              ¿Estás seguro de que deseas cambiar el{' '}
              <strong>
                {pendingChange?.type === 'status' && selectedStatus}
                {pendingChange?.type === 'clientType' && selectedClientType}
              </strong>{' '}
              a <strong>{pendingChange?.newValue}</strong>?
            </p>
            <label className="block mt-4">
              <span className="text-sm text-tecnofi-text">Razón para realizar el cambio:</span>
              <input
                type="text"
                className="mt-1 block w-full px-3 py-2 border border-tecnofi-primary rounded-lg focus:outline-none"
                placeholder="Escribe la razón..."
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </label>
            <div className="flex justify-end gap-2 mt-4">
              <Button
                className="rounded-[4px] my-auto px-[8px] py-[4px]"
                onClick={() => setShowConfirmModal(false)}
              >
                Cancelar
              </Button>
              <Button
                className="rounded-[4px] my-auto px-[8px] py-[4px]"
                variant="Primary"
                onClick={() => confirmChange(pendingChange?.type!)}
              >
                Confirmar
              </Button>
            </div>
          </div>
        </div>
      )}

      {showRolesModal && (
        <div className="fixed z-50 inset-0 bg-[#4444] flex justify-center items-center">
          <div className="bg-tecnofi-background p-4 rounded-lg shadow-md w-[400px] max-h-[80vh] overflow-y-auto">
            <h2 className="text-tecnofi-primary text-lg font-bold">Editar Roles</h2>
            <p>¿Cuáles son los roles de este usuario?</p>
            <div className="max-h-[300px] overflow-y-auto">
              {roles.map((role) => (
                <label
                  key={role.id}
                  className="flex gap-2 items-center py-1 px-2 cursor-pointer hover:bg-tecnofi-section rounded-md"
                >
                  <input
                    className="h-5 w-5 accent-tecnofi-primary border border-tecnofi-primary rounded-sm transition duration-300 ease-in-out"
                    type="checkbox"
                    checked={selectedRoles.some(r => r.id === role.id)}
                    onChange={() => handleRoleChange(role.id)}
                  />
                  {role.name}
                </label>
              ))}
            </div>
            <label className="block mt-4">
              <span className="text-sm text-tecnofi-text">
                Razón para realizar el cambio:
              </span>
              <input
                type="text"
                className="mt-1 block w-full px-3 py-2 border border-tecnofi-primary rounded-lg focus:outline-none"
                placeholder="Escribe la razón..."
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </label>
            <div className="flex justify-end gap-2 mt-4">
              <Button
                onClick={() => setShowRolesModal(false)}
                className="rounded-[4px] my-auto px-[8px] py-[4px]"
              >
                Cancelar
              </Button>
              <Button
                variant="Primary"
                onClick={saveRoles}
                className="rounded-[4px] my-auto px-[8px] py-[4px]"
              >
                Guardar
              </Button>
            </div>
          </div>
        </div>
      )}

      <h1 className="text-center text-2xl font-bold text-tecnofi-text px-8 py-4">
        Cliente {users && (users.profile.fullName)}
      </h1>
      <article className="w-full max-w-full overflow-x-scroll">
        {users && (
          <>
            <p className="px-4 py-2 whitespace-nowrap text-sm font-medium text-tecnofi-text">
              <span className="font-semibold">Id del Usuario: </span>{users.getUser?.id || 'N/A'}
            </p>
            <p className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text">
              <span className="font-semibold">Nombre Completo: </span>{users.profile?.fullName || 'N/A'}
            </p>
            <p className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text">
              <span className="font-semibold">Identificación de Empresa/Persona: </span>{users.profile?.identificationNumber || 'N/A'}
            </p>
            <p className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text">
              <span className="font-semibold">Teléfono: </span>{users.profile?.contactPhone || 'N/A'}
            </p>
            <p className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text">
              <span className="font-semibold">Correo Electrónico: </span>{users.getUser?.email || 'N/A'}
            </p>
            <p className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text">
              <span className="font-semibold">Nombre de la Empresa: </span>{users.profile?.businessName || 'N/A'}
            </p>
            <p className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text">
              <span className="font-semibold">Correo de Facturación: </span>{users.profile?.billingEmail || 'N/A'}
            </p>
            <p className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text">
              <span className="font-semibold">Cargo: </span>{users.profile?.position || 'N/A'}
            </p>

            {/* Roles Dropdown */}
            <div className="flex">
              <p className="px-4 py-2 whitespace-nowrap text-sm font-medium text-tecnofi-text">
                <span className="font-semibold">Roles: </span>{users.getUser.roles.map(role => role.name).join(', ') || 'N/A'}
              </p>
              <Button
                className="flex justify-between items-center text-sm text-tecnofi-text border border-tecnofi-primary px-[12px] py-[4px] rounded-lg cursor-pointer"
                onClick={() => setShowRolesModal(true)}
              >
                Editar Roles
              </Button>
            </div>

            {/* Estado del Cliente Dropdown */}
            <div className="flex items-center text-sm text-tecnofi-text px-4 py-2">
              <span className="font-semibold me-2">Estado del Cliente: </span>
              <div className="flex relative">
                <label
                  className="flex justify-between items-center text-tecnofi-text border border-tecnofi-primary px-[12px] py-[4px] rounded-lg cursor-pointer"
                  onClick={() => toggleDropdown('status')}
                >
                  {selectedStatus || 'Seleccione el Estado'}
                </label>
                {openDropdown === 'status' && (
                  <div
                    className="absolute top-0 left-0 bg-tecnofi-background border border-tecnofi-primary rounded-lg mt-1 z-50 shadow-md w-[200px]"
                    style={{ top: "100%", minWidth: "250px" }}
                  >
                    {['ACTIVE', 'PENDING', 'INACTIVE'].map((status) => (
                      <div
                        key={status}
                        className="py-2 px-4 cursor-pointer rounded-lg hover:bg-tecnofi-section"
                        onClick={() => updateStatus(users.getUser?.id || 'N/A', status, openDropdown)}
                      >
                        {status}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>

            {/* Tipo de Cliente Dropdown */}
            <div className="flex items-center text-sm text-tecnofi-text px-4 py-2">
              <span className="font-semibold me-2">Tipo de Cliente: </span>
              <div className="flex relative">
                <label
                  className="flex justify-between items-center text-tecnofi-text border border-tecnofi-primary px-[12px] py-[4px] rounded-lg cursor-pointer"
                  onClick={() => toggleDropdown('clientType')}
                >
                  {selectedClientType || 'Seleccione el Tipo de Cliente'}
                </label>
                {openDropdown === 'clientType' && (
                  <div
                    className="absolute top-0 left-0 bg-tecnofi-background border border-tecnofi-primary rounded-lg mt-1 z-50 shadow-md w-[200px]"
                    style={{ top: "100%", minWidth: "250px" }}
                  >
                    {['CLIENT_FINAL', 'DISTRIBUTOR', 'SOLUTION_INTEGRATOR', 'GOVERMENT', 'TECHNICAL'].map((type) => (
                      <div
                        key={type}
                        className="py-2 px-4 cursor-pointer rounded-lg hover:bg-tecnofi-section"
                        onClick={() => updateClientType(users.getUser?.id || 'N/A', type, openDropdown)}
                      >
                        {type}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <p className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text">
              <span className="font-semibold">URL del RUT: </span>{users.profile?.urlRUT || 'N/A'}
            </p>
            <p className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text">
              <span className="font-semibold">Dirección Primaria: </span>{users.profile?.primaryAddress || 'N/A'}
            </p>
            <p className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text">
              <span className="font-semibold">Dirección Secundaria: </span>{users.profile?.secondaryAddress || 'N/A'}
            </p>
            <p className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text">
              <span className="font-semibold">Ciudad de Dirección: </span>{users.profile?.location?.municipality || 'N/A'}
            </p>
            <p className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text">
              <span className="font-semibold">Persona Jurídica: </span>{users.getUser?.legalPersonType ? 'Yes' : 'No'}
            </p>
            <p className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text">
              <span className="font-semibold">Autorización de Tratamiento de Datos: </span>{users.profile?.dataTreatmentAuthorization ? 'Yes' : 'No'}
            </p>
            <p className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text">
              <span className="font-semibold">Autorización a Notificaciones: </span>{users.profile?.notificationAuthorization ? 'Yes' : 'No'}
            </p>
            <p className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text">
              <span className="font-semibold">Fecha de Creación: </span>{users.profile?.createdAt ? formatDate(users.profile.createdAt) : 'N/A'}
            </p>
            <p className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text">
              <span className="font-semibold">Fecha de Actualización: </span>{users.profile?.updatedAt ? formatDate(users.profile.updatedAt) : 'N/A'}
            </p>
            <p className="px-4 py-2 whitespace-nowrap text-sm text-tecnofi-text">
              <span className="font-semibold">Comentarios sobre el cliente: </span>{users.getUser?.comments || 'Ninguno'}
            </p>
          </>
        )}
      </article>
    </section>
  );
}

export default User;
