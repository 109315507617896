import React from 'react';
import { Helmet } from 'react-helmet';
import { Wrapper } from '../../component';
import GetRoles from './component/GetRoles';

function Roles() {
  return (
    <>
      <Helmet>
        <title>Tecnoficom | Gestión de Roles</title>
        <meta
          name="description"
          content="Administra y configura roles de usuario en Tecnoficom. Define permisos, asigna responsabilidades y gestiona el acceso de manera eficiente para una mejor seguridad y organización."
        />
        <meta
          name="keywords"
          content="Tecnoficom, gestión de roles, administración de usuarios, permisos de acceso, seguridad informática, control de permisos, configuración de roles, administración empresarial, gestión interna"
        />
      </Helmet>
      <Wrapper>
        <h3 className="text-tecnofi-text text-lg font-semibold text-center mt-6 mb-6">
          Gestión de Roles
        </h3>
        <GetRoles />
      </Wrapper>
    </>
  );
};

export default Roles;
