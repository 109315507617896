import { useState } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { toast } from 'react-toastify';
import { JwtPayload, Permission } from './types';
import { useNavigate } from 'react-router-dom';

const useAuth = () => {
  const [authToken, setAuthToken] = useState<string | null>(localStorage.getItem('authToken'));
  const navigate = useNavigate();

  const getUserInfo = (): JwtPayload | null => {
    try {
      const token = localStorage.getItem('authToken');
      return token ? jwtDecode<JwtPayload>(token) : null;
    } catch (error) {
      console.error('Error al decodificar el token:', error);
      return null;
    }
  };

  const cachePermissions = () => {
    const userInfo = getUserInfo();
    if (userInfo) {
      const permissions = userInfo.roles.flatMap(role => role.permissions);
      localStorage.setItem('userPermissions', JSON.stringify(permissions));
    }
  };

  const hasPermission = (...permisos: Permission[]): boolean => {
    const cachedPermissions: string[] = JSON.parse(localStorage.getItem('userPermissions') || '[]');
    return permisos.some(permission => cachedPermissions.includes(permission));
  };

  const verifyToken = async () => {
    if (!authToken) return;
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_TECNOFICOM}/auth/user/verify`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });

      const { token } = response.data;
      if (token) {
        setAuthToken(token);
        localStorage.setItem('authToken', token);
        cachePermissions();
        return true;
      }
      return false;
    } catch {
      logout();
      toast.error('La sesión ha expirado. Inicia sesión nuevamente.');
    }
  };

  const login = async (email: string, password: string) => {
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_TECNOFICOM}/auth/login`, { email, password });
      localStorage.setItem('authToken', data.token);
      setAuthToken(data.token);
      cachePermissions();
      window.location.reload();
    } catch {
      throw new Error('Error al iniciar sesión');
    }
  };

  const logout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('userPermissions');
    setAuthToken(null);
    navigate('/');
  };

  return { login, logout, getUserInfo, hasPermission, isAuthenticated: () => !!authToken, authToken, verifyToken };
};

export default useAuth;
