import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { Brand, Home, Categories, Product, ShoppingCart, SearchProduct, Orders, Clients, AdminProducts, Reports, NotFound, Dashboard, Quotes, AdminOrders, Account, Congurations, Contents, Roles } from './pages';
import { Window, ProtectedRoutes, Politics, Access } from './component';
import { Producto } from './pages/Product/component/types';
import { DashboardLayout, GeneralLayout } from './layout';
import Wishlists from './pages/Wishlist/Wishlist';
import useAuth from './hooks/useAuth';
import { ChangeData, ChangePassword, ForgottenPassword, LoginRedirect, MyAccount, RecoveryPassword } from './pages/Account/pages';
import { CreateImage, CreateProduct, GetProduct, ProductImages, UpdateProduct, UploadProduct, GetCategories, CreateCategories, GetBrands, LowStock } from './pages/AdminProducts/pages';
import GetAllProduct from './pages/AdminProducts/pages/GetAllProducts';
import { ToastContainer } from 'react-toastify';
import { AllUsers, User } from './pages/Clients/pages';
import { CreateSections, Sections, UpdateSections } from './pages/Contents/pages';
import useFetchTheme from './hooks/useFetchTheme';
import { Permission } from './hooks/types';

function App() {
  const { isAuthenticated } = useAuth();

  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [login, setLogin] = useState(false);
  const [politics, setPolitics] = useState(false);
  const [isLoginMode, setIsLoginMode] = useState(false);
  const [cart, setCart] = useState<Producto[]>([]);

  const themeConfig = useFetchTheme(isDarkMode);

  useEffect(() => {
    const checkAuth = async () => {
      const authenticated = await isAuthenticated();
      setIsAuth(authenticated);
    };
    checkAuth();
  }, [isAuthenticated]);

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  const toggleLogin = (isLogin?: boolean) => {
    setLogin(!login);
    toggleAuthMode(isLogin);
  };

  const toggleAuthMode = (isLogin?: boolean) => {
    setIsLoginMode(isLogin ?? !isLoginMode);
  };

  const togglePolitics = () => {
    setPolitics(!politics);
  };

  const handleLoginPrompt = () => {
    toggleLogin();
  };

  const addToCart = (productToAdd: Producto, quantityToAdd: number = 1) => {
    const existingProductIndex = cart.findIndex(
      product => product.producto_id === productToAdd.producto_id
    );
    if (existingProductIndex !== -1) {
      const updatedCart = [...cart];
      updatedCart[existingProductIndex].quantity += quantityToAdd;
      setCart(updatedCart);
    } else {
      setCart(prevCart => [...prevCart, { ...productToAdd, quantity: quantityToAdd }]);
    }
  };

  return (
    <div className={`flex flex-col min-h-screen transition-colors`}>
      <ToastContainer />
      {login && (
        <Window onClose={() => toggleLogin()}>
          <Access
            isLoginMode={isLoginMode}
            toggleAuthMode={toggleAuthMode}
            togglePolitics={togglePolitics}
            toggleLogin={toggleLogin}
          />
        </Window>
      )}
      {politics && (
        <Window onClose={() => togglePolitics()}>
          <Politics />
        </Window>
      )}
      <Routes>
        <Route path="/" element={<GeneralLayout toggleTheme={toggleTheme} toggleLogin={toggleLogin} valueLogin={login} togglePolitics={togglePolitics} />}>
          <Route index path="/" element={<Home toggleLogin={toggleLogin} />} />
          <Route path="/brand/:id/:nombre" element={<Brand isAuthenticated={isAuth} toggleLogin={toggleLogin} />} />
          <Route path="/categories/:id/:nombre" element={<Categories isAuthenticated={isAuth} toggleLogin={toggleLogin} />} />
          <Route path="/product/:id/:nombre" element={<Product isAuthenticated={isAuth} addToCart={addToCart} toggleLogin={toggleLogin} />} />
          <Route path="/search/:busqueda" element={<SearchProduct isAuthenticated={isAuth} toggleLogin={toggleLogin} />} />
          <Route path="/user/forgotten-password" element={<ForgottenPassword />} />
          <Route path="/user/:id/recovery-password" element={<RecoveryPassword />} />
          <Route element={<ProtectedRoutes requiredPermissions={[Permission.USER]} onLoginPrompt={handleLoginPrompt} />}>
            <Route path="user" element={<LoginRedirect setLogin={setLogin} setIsLoginMode={setIsLoginMode} />} />
            <Route path="user/:id" element={<Account />}>
              <Route path="" element={<MyAccount />} />
              <Route path="/user/:id/change-data" element={<ChangeData />} />
              <Route path="/user/:id/change-password" element={<ChangePassword />} />
            </Route>
            <Route path="shopping-cart" element={<ShoppingCart />} />
            <Route path="wishlist" element={<Wishlists />} />
            <Route path="orders" element={<Orders />} />
            <Route path="quotes" element={<Quotes />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="/dashboard" element={<DashboardLayout toggleTheme={toggleTheme} toggleLogin={toggleLogin} />}>
          <Route element={<ProtectedRoutes requiredPermissions={[Permission.ADMIN_CLIENTS]} onLoginPrompt={handleLoginPrompt} />}>
            <Route path="" element={<Dashboard />} />
            <Route path="clients" element={<Clients />}>
              <Route path="" element={<AllUsers />} />
              <Route path=":id" element={<User />} />
            </Route>
          </Route>
          <Route path="admin-products" element={<AdminProducts />}>
            <Route element={<ProtectedRoutes requiredPermissions={[Permission.ADMIN_PRODUCTS]} onLoginPrompt={handleLoginPrompt} />}>
              <Route path="" element={<GetAllProduct />} />
              <Route path=":id" element={<GetProduct />} />
              <Route path="create" element={<CreateProduct />} />
              <Route path="update/:id" element={<UpdateProduct />} />
              <Route path="upload" element={<UploadProduct />} />
            </Route>
            <Route element={<ProtectedRoutes requiredPermissions={[Permission.ADMIN_STOCK]} onLoginPrompt={handleLoginPrompt} />}>
              <Route path="stock" element={<LowStock />} />
            </Route>
            <Route element={<ProtectedRoutes requiredPermissions={[Permission.ADMIN_IMAGES]} onLoginPrompt={handleLoginPrompt} />}>
              <Route path="images" element={<ProductImages />} />
              <Route path="images/upload" element={<CreateImage />} />
            </Route>
            <Route element={<ProtectedRoutes requiredPermissions={[Permission.ADMIN_TAGS]} onLoginPrompt={handleLoginPrompt} />}>
              <Route path="categories" element={<GetCategories />} />
              <Route path="categories/create" element={<CreateCategories />} />
            </Route>
            <Route element={<ProtectedRoutes requiredPermissions={[Permission.ADMIN_BRANDS]} onLoginPrompt={handleLoginPrompt} />}>
              <Route path="brands" element={<GetBrands />} />
              <Route path="brands/create" element={<ProductImages />} />
            </Route>
          </Route>
          <Route element={<ProtectedRoutes requiredPermissions={[Permission.ADMIN_ROLES]} onLoginPrompt={handleLoginPrompt} />}>
            <Route path="roles" element={<Roles />} />
          </Route>
          <Route element={<ProtectedRoutes requiredPermissions={[Permission.ADMIN_ORDERS]} onLoginPrompt={handleLoginPrompt} />}>
            <Route path="history" element={<AdminOrders />} />
          </Route>
          <Route element={<ProtectedRoutes requiredPermissions={[Permission.ADMIN_REPORTS]} onLoginPrompt={handleLoginPrompt} />}>
            <Route path="reports" element={<Reports />} />
          </Route>
          <Route element={<ProtectedRoutes requiredPermissions={[Permission.ADMIN_CONFIG]} onLoginPrompt={handleLoginPrompt} />}>
            <Route path="config" element={<Congurations />} />
          </Route>
          <Route element={<ProtectedRoutes requiredPermissions={[Permission.ADMIN_CONTENTS]} onLoginPrompt={handleLoginPrompt} />}>
            <Route path="contents" element={<Contents />}>
              <Route path="" element={<Sections />} />
              <Route path="create" element={<CreateSections />} />
              <Route path="update/:id" element={<UpdateSections />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </div >
  );
}

export default App;
