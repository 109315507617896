import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import { toast } from 'react-toastify';

interface LoginRedirectProps {
  setLogin: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoginMode: React.Dispatch<React.SetStateAction<boolean>>;
}

const LoginRedirect: React.FC<LoginRedirectProps> = ({ setLogin, setIsLoginMode }) => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    const checkAuth = async () => {
      const isAuth = await isAuthenticated();

      if (isAuth) {
        toast.success('Ya te encuentras autenticado.');
        navigate('/');
      } else {
        setLogin(true);
        setIsLoginMode(true);
        navigate('/');
      }
    };

    checkAuth();
  }, [isAuthenticated, setLogin, setIsLoginMode, navigate]);

  return null;
};

export default LoginRedirect;