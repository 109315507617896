import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import useAuth from '../../../hooks/useAuth';
import { OneUser } from './types';

const MyAccount = () => {
  const { id } = useParams<{ id: string }>();
  const { authToken } = useAuth();
  const [user, setUser] = useState<OneUser | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get<OneUser>(`${process.env.REACT_APP_BACKEND_TECNOFICOM}/auth/${id}`, {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        });
        setUser(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [id, authToken]);

  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const capitalizeRoles = (roles: string[]) => {
    return roles.map(role => capitalizeFirstLetter(role)).join(', ');
  };

  if (!user) return <div></div>;

  return (
    <div className="container text-tecnofi-text mx-auto p-4">
      <h2 className="text-tecnofi-text text-xl font-semibold text-center mt-6 mb-2">
        ¡Bienvenido! Aqui tienes toda tu información.
      </h2>
      <section className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-tecnofi-section shadow-md p-4 rounded-lg">
          <h3 className="text-lg text-center font-semibold mb-3">Información del Cliente</h3>
          <p><strong>Email:</strong> {user.getUser.email}</p>
          <p>
            <strong>Roles:</strong>{" "}
            {user.getUser.roles && user.getUser.roles.length > 0
              ? capitalizeRoles(user.getUser.roles.map(role => role.name))
              : "N/A"}
          </p>

          <p><strong>Status:</strong> {capitalizeFirstLetter(user.getUser.clientStatus)}</p>
          <p><strong>Tipo de Cliente:</strong> {capitalizeFirstLetter(user.getUser.clientType)}</p>
          <p><strong>Es Persona Jurídica:</strong> {user.getUser.legalPersonType ? 'Sí' : 'No'}</p>
        </div>

        <article className="bg-tecnofi-section shadow-md p-4 rounded-lg">
          <h3 className="text-lg text-center font-semibold mb-3">Información del Perfil</h3>
          <p><strong>Nombre Completo:</strong> {user.profile.fullName}</p>
          <p><strong>Número de Identificación:</strong> {user.profile.identificationNumber}</p>
          <p><strong>Teléfono de Contacto:</strong> {user.profile.contactPhone}</p>
          <p><strong>Municipio o Ciudad:</strong> {user.profile.location.municipality}</p>
          <p><strong>Dirección Principal:</strong> {user.profile.primaryAddress}</p>
          <p><strong>Dirección Secundaria:</strong> {user.profile.secondaryAddress || 'N/A'}</p>
          <p><strong>Razón Social:</strong> {user.profile.businessName || 'N/A'}</p>
          <p><strong>Email de Facturación:</strong> {user.profile.billingEmail || 'N/A'}</p>
          <p><strong>Posición:</strong> {user.profile.position}</p>
        </article>
        <article className="mt-6 flex justify-center items-center col-span-2 gap-4">
          <Link
            to={`/user/${user.getUser.id}/change-data`}
            className="bg-tecnofi-primary text-[#fff] font-medium py-2 px-4 rounded-lg active:bg-tecnofi-secondary transition-colors"
          >
            Cambiar tus datos
          </Link>
          <Link
            to={`/user/${user.getUser.id}/change-password`}
            className="bg-tecnofi-primary text-[#fff] font-medium py-2 px-4 rounded-lg active:bg-tecnofi-secondary transition-colors"
          >
            Cambiar tu contraseña
          </Link>
        </article>
      </section>

    </div>
  );

};

export default MyAccount;
