import React, { useEffect, useState } from 'react';
import useAuth from '../../../hooks/useAuth';
import axios from 'axios';
import { Brand } from './types';
import { Button, Wrapper } from '../../../component';
import { toast } from 'react-toastify';
import { MdOutlineCreate } from 'react-icons/md';
import { IoMdTrash } from 'react-icons/io';

function GetBrands() {
  const { authToken } = useAuth();
  const [brands, setBrands] = useState<Brand[]>([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [newBrandName, setNewBrandName] = useState('');
  const [brandIdToDelete, setBrandIdToDelete] = useState<string | null>(null);

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
        const brands = await axios.get<Brand[]>(
          `${process.env.REACT_APP_BACKEND_TECNOFICOM}/brand`,
          { headers }
        );
        setBrands(brands.data);
      } catch (error) {
        toast.error('Error del backend al solicitar las marcas.');
      }
    };

    fetchBrands();
  }, [authToken]);

  const handleDeleteClick = (id: string) => {
    setBrandIdToDelete(id);
    setShowConfirmModal(true);
  };

  const handleCloseModal = () => {
    setShowConfirmModal(false);
    setShowCreateModal(false);
    setNewBrandName('');
  };

  const deleteBrand = async () => {
    if (!brandIdToDelete) return;

    try {
      const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
      await axios.delete(`${process.env.REACT_APP_BACKEND_TECNOFICOM}/brand/${brandIdToDelete}`, { headers });
      toast.info('Se ha eliminado la marca correctamente.');
      setBrands(brands.filter(brand => brand.id !== brandIdToDelete));
      setShowConfirmModal(false);
    } catch (error) {
      toast.error('Error del backend al eliminar la marca.');
    }
  };

  const handleCreateBrand = async () => {
    if (!newBrandName || /[^a-zA-Z0-9\s]/.test(newBrandName)) {
      toast.error('El nombre de la marca no puede contener símbolos.');
      return;
    }

    try {
      const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_TECNOFICOM}/brand`,
        { name: newBrandName },
        { headers }
      );
      toast.success('Marca creada correctamente.');
      setBrands([...brands, response.data]);
      handleCloseModal();
    } catch (error) {
      toast.error('Error al crear la marca.');
    }
  };

  return (
    <Wrapper>
      {showConfirmModal && (
        <section className="fixed z-50 inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <article className="flex flex-col bg-tecnofi-background p-4 rounded-lg shadow-md w-1/3">
            <h3 className="text-tecnofi-text text-lg font-medium mb-4">
              ¿Estás seguro de que quieres eliminar esta marca?
            </h3>
            <span className="flex justify-between">
              <Button
                className="flex relative rounded-lg my-auto px-4 py-2"
                onClick={handleCloseModal}
              >
                Cancelar
              </Button>
              <Button
                type="button"
                variant="Primary"
                className="flex relative rounded-lg my-auto px-4 py-2"
                onClick={deleteBrand}
              >
                Confirmar
              </Button>
            </span>
          </article>
        </section>
      )}

      {showCreateModal && (
        <section className="fixed z-50 inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <article className="flex flex-col bg-tecnofi-background p-4 rounded-lg shadow-md w-1/3">
            <h3 className="text-tecnofi-text text-lg font-medium mb-4">
              Crear nueva marca
            </h3>
            <input
              type="text"
              value={newBrandName}
              onChange={(e) => setNewBrandName(e.target.value)}
              placeholder="Nombre de la marca"
              className="flex justify-start items-center border-[2px] border-tecnofi-primary rounded-[8px] text-tecnofi-text w-full px-4 h-[52px] bg-tecnofi-section placeholder-tecnofi-faded-text focus:outline-none"
            />
            <span className="flex justify-between mt-4">
              <Button
                className="rounded px-4 py-2"
                onClick={handleCloseModal}
              >
                Cancelar
              </Button>
              <Button
                type="button"
                variant="Primary"
                className="flex relative rounded-lg my-auto px-4 py-2"
                onClick={handleCreateBrand}
              >
                Crear
              </Button>
            </span>
          </article>
        </section>
      )}

      <section>
        <Button
          type="button"
          variant="Primary"
          className="flex justify-center items-center rounded-lg gap-2 px-4 py-2 my-4"
          onClick={() => setShowCreateModal(true)}
        >
          <MdOutlineCreate size={16} />
          Crear Marca
        </Button>
        <article>
          <table className="max-w-full border-separate border-spacing-0">
            <thead>
              <tr className="bg-tecnofi-background text-tecnofi-text text-sm font-medium">
                <th className="px-4 py-2 border-b-2 border-tecnofi-border">ID</th>
                <th className="px-4 py-2 border-b-2 border-tecnofi-border">Nombre</th>
                <th className="px-4 py-2 border-b-2 border-tecnofi-border"></th>
              </tr>
            </thead>
            <tbody>
              {brands.map((brand) => (
                <tr key={brand.id} className="border-b-[1px] border-tecnofi-border">
                  <td className="px-4 py-2">{brand.id}</td>
                  <td className="px-4 py-2">{brand.name}</td>
                  <td className="px-4 py-2 text-center">
                    <Button
                      type="button"
                      variant="Primary"
                      className="flex justify-center items-center rounded-[4px] my-auto px-[4px] py-[4px]"
                      onClick={() => handleDeleteClick(brand.id)}
                    >
                      <IoMdTrash size={16} fill="#FFF" />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </article>

      </section>
    </Wrapper>
  );
}

export default GetBrands;