import React, { useEffect, useState } from 'react';
import { Button, Wrapper } from '../../../component';
import useAuth from '../../../hooks/useAuth';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { ShoppingCartResponse } from './types';
import { IoMdTrash } from 'react-icons/io';

interface GetShoppingCartProps {
  onTotalCostChange: (totalCost: number) => void;
}

function GetShoppingCart({ onTotalCostChange }: GetShoppingCartProps) {
  const { authToken } = useAuth();
  const [shoppingCart, setShoppingCart] = useState<ShoppingCartResponse>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
        const response = await axios.get<ShoppingCartResponse>(
          `${process.env.REACT_APP_BACKEND_TECNOFICOM}/shopping-cart`,
          { headers }
        );
        setShoppingCart(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [authToken]);

  useEffect(() => {
    if (shoppingCart) {
      const totalCost = shoppingCart.items.reduce(
        (sum, product) => sum + product.price * product.quantity,
        0
      );
      onTotalCostChange(totalCost);
    }
  }, [shoppingCart, onTotalCostChange]);

  const incrementQuantity = async (id: string) => {
    try {
      const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
      const endpoint = `${process.env.REACT_APP_BACKEND_TECNOFICOM}/shopping-cart/add`;
      const body = { items: [{ quantity: 1, productId: id }] };
      await axios.patch(endpoint, body, { headers });

      setShoppingCart((prevShoppingCart) => {
        if (!prevShoppingCart) return prevShoppingCart;
        const updatedItems = prevShoppingCart.items.map((product) => {
          if (product.id === id) {
            return { ...product, quantity: product.quantity + 1 };
          }
          return product;
        });
        return { ...prevShoppingCart, items: updatedItems };
      });
    } catch (error) {
      console.error("Error al incrementar la cantidad:", error);
    }
  };

  const decrementQuantity = async (id: string) => {
    try {
      const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
      const endpoint = `${process.env.REACT_APP_BACKEND_TECNOFICOM}/shopping-cart/remove`;
      const body = { items: [{ quantity: 1, productId: id }] };
      await axios.patch(endpoint, body, { headers });

      setShoppingCart((prevShoppingCart) => {
        if (!prevShoppingCart) return prevShoppingCart;
        const updatedItems = prevShoppingCart.items.map((product) => {
          if (product.id === id && product.quantity > 1) {
            return { ...product, quantity: product.quantity - 1 };
          }
          return product;
        });
        return { ...prevShoppingCart, items: updatedItems };
      });
    } catch (error) {
      console.error("Error al decrementar la cantidad:", error);
    }
  };

  const removeProduct = async (id: string) => {
    try {
      const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
      const productToRemove = shoppingCart?.items.find(product => product.id === id);
      if (!productToRemove) return;
      const productsToRemove = {
        items: [{
          quantity: productToRemove.quantity,
          productId: id
        }]
      };
      const endpoint = `${process.env.REACT_APP_BACKEND_TECNOFICOM}/shopping-cart/remove`;
      await axios.patch(endpoint, productsToRemove, { headers });

      setShoppingCart((prevShoppingCart) => {
        if (!prevShoppingCart) return prevShoppingCart;
        const updatedItems = prevShoppingCart.items.filter((product) => product.id !== id);
        return { ...prevShoppingCart, items: updatedItems };
      });
    } catch (error) {
      console.error("Error al eliminar el producto:", error);
    }
  };

  function formatCurrency(value: number | string | undefined): string {
    if (value === undefined) return '';
    const numberValue = typeof value === 'string' ? parseFloat(value) : value;
    return numberValue.toLocaleString('es-CO', { style: 'currency', currency: 'COP' });
  }

  return (
    <>
      {shoppingCart && shoppingCart?.items?.length > 0 ? (
        <Wrapper>
          <section className="flex flex-col gap-2">
            {shoppingCart.items.map((product) => (
              <article
                key={product.id}
                className="flex justify-between items-center h-24 w-full bg-tecnofi-section text-tecnofi-text rounded-lg gap-2 px-2 py-2"
              >
                <Link to={`/product/${product.id}/${encodeURIComponent(product.name)}`} className="flex gap-2">
                  <img
                    src={product?.images?.product_url ? product.images.product_url : '/favicon.ico'}
                    alt={product.name}
                    className="bg-[#fff] w-auto h-20 object-cover rounded-md"
                  />
                  <div className="flex flex-col">
                    <h3 className="text-sm font-normal line-clamp-2">{product.name}</h3>
                    <p className="text-sm text-tecnofi-faded-text">{product.model}</p>
                  </div>
                </Link>
                <div className="flex flex-col justify-center items-end gap-2">
                  <p className="text-tecnofi-primary font-bold">{formatCurrency(product.price)}</p>
                  <div className="flex gap-2">
                    <Button
                      type="button"
                      variant="Primary"
                      className="text-lg rounded-[4px] leading-none px-[4px] py-[2px]"
                      onClick={() => decrementQuantity(product.id)}
                    >
                      -
                    </Button>
                    <p className="text-sm text-tecnofi-primary">{product.quantity}</p>
                    <Button
                      type="button"
                      variant="Primary"
                      className="text-lg rounded-[4px] leading-none px-[4px] py-[2px]"
                      onClick={() => incrementQuantity(product.id)}
                    >
                      +
                    </Button>
                  </div>
                  <Button
                    type="button"
                    variant="Primary"
                    className="flex relative rounded-[4px] my-auto px-[4px] py-[4px]"
                    onClick={() => removeProduct(product.id)}
                  >
                    <IoMdTrash size={16} fill="#FFF" />
                  </Button>
                </div>
              </article>
            ))}
          </section>
        </Wrapper>
      ) : (
        <p>No hay productos en el carrito.</p>
      )}
    </>
  );
}

export default GetShoppingCart;