import React from 'react';
import ProductosDestacados from './component/ProductosDestacados';
import { Helmet } from 'react-helmet';
import DynamicHome from './component/DynamicHome';

interface HomeProps {
  toggleLogin: (isLogin?: boolean) => void;
}

function Home({ toggleLogin }: HomeProps) {
  return (
    <div className="flex flex-col justify-center">
      <Helmet>
        <title>Tecnoficom | Home</title>
        <meta name="description" content="Empresa líder en telecomunicaciones, enfocada en la excelencia y el servicio al cliente a nivel nacional." />
        <meta name="keywords" content="Tecnoficom, productos, telecomunicaciones, ventas, precios, servicio, nacional, infraestructura, profesionales, técnicos, soluciones, conectividad, redes, tecnología, calidad"/>
      </Helmet>
      <DynamicHome />
      {/* <ProductosDestacados isAuthenticated={isAuthenticated} toggleLogin={toggleLogin} /> */}
    </div>
  );
}

export default Home;
