'use client';
import { type ButtonProps } from './types';

function Button({
  children,
  className,
  variant,
  type,
  onClick = () => { },
  disabled = false
}: ButtonProps) {
  let variantClass = '';
  switch (variant) {
    case 'Primary':
      variantClass = 'bg-tecnofi-primary active:bg-tecnofi-secondary text-[#fff]';
      break;
    case 'Secondary':
      variantClass = 'bg-tecnofi-secondary active:bg-tecnofi-primary text-tecnofi-text';
      break;
    case 'Dark':
      variantClass = "bg-tecnofi-dark active:brightness-90 text-[#FFF]";
      break;
    case 'Default':
      variantClass = "bg-tecnofi-background active:brightness-90 text-tecnofi-text";
      break;
    default:
      variantClass = 'Default';
  }

  return (
    <div className='flex-shrink-0'>
      <button className={`${variantClass} ${className}`} onClick={onClick} type={type} disabled={disabled}>
        {children}
      </button>
    </div>
  );
}

export default Button;