import React, { useEffect, useState } from 'react';
import { Button, Wrapper } from '../../../component';
import useAuth from '../../../hooks/useAuth';
import axios from 'axios';
import { OrderResponse } from './types';
import { MdOutlineSimCardDownload } from 'react-icons/md';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';

function GetOrder() {
  const { authToken } = useAuth();
  const [orders, setOrders] = useState<OrderResponse | null>(null);
  const [pagina, setPagina] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
        const response = await axios.get<OrderResponse>(
          `${process.env.REACT_APP_BACKEND_TECNOFICOM}/order`,
          { headers, params: { offset: pagina - 1, limit: 40 } }
        );
        setOrders(response.data);
        setTotalPaginas(response.data.totalPages);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [authToken, pagina]);

  const handleNextPage = () => {
    if (pagina < totalPaginas) {
      setPagina((prevPagina) => prevPagina + 1);
    }
  };

  const handlePreviousPage = () => {
    if (pagina > 1) {
      setPagina((prevPagina) => prevPagina - 1);
    }
  };

  const downloadOrder = async (id: string, customId: string) => {
    try {
      const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_TECNOFICOM}/order/${id}`,
        { headers, responseType: 'blob' }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `order_${customId}.pdf`);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      toast.success('Archivo descargado.');
    } catch (error) {
      toast.error('Hubo un problema al descargar el archivo.');
    }
  };

  return (
    <Wrapper>
      <ToastContainer />
      {totalPaginas > 1 && (
        <section className="flex justify-between items-center font-medium mt-4">
          <Button
            type="button"
            className="rounded-full p-[8px] bg-tecnofi-primary text-white"
            onClick={handlePreviousPage}
            disabled={pagina === 1}
          >
            <FaAngleLeft size="22" fill="#fff" />
          </Button>
          <span className="text-tecnofi-text text-center font-medium">
            Página {pagina} de {totalPaginas}
          </span>
          <Button
            type="button"
            className="rounded-full p-[8px] bg-tecnofi-primary text-white"
            onClick={handleNextPage}
            disabled={pagina === totalPaginas}
          >
            <FaAngleRight size="22" fill="#fff" />
          </Button>
        </section>
      )}
      {orders ? (
        <section>
          {orders.orders.map((order) => (
            <article key={order.id} className="flex flex-col bg-tecnofi-section text-tecnofi-text p-4 mb-4 rounded-lg shadow-md">
              <div className="flex justify-between items-center">
                <h4 className="text-lg font-semibold">Orden ID: {order.customId}</h4>
                <Button variant="Primary" className="flex relative rounded-[4px] my-auto px-[4px] py-[4px]" onClick={() => downloadOrder(order.id, order.customId)}>
                  <MdOutlineSimCardDownload size={22} fill="#FFF" />
                </Button>
              </div>
              <div className="grid md:grid-cols-3 sm:grid-cols-2">
                <p className="text-sm"><strong>Status:</strong> {order.status}</p>
                <p className="text-sm"><strong>Metodo de Pago:</strong> {order.payMethod}</p>
                <p className="text-sm"><strong>Total:</strong> {order.totalOrder ? order.totalOrder.toLocaleString('es-CO', { style: 'currency', currency: 'COP' }) : 'N/A'}</p>
                <p className="text-sm"><strong>ReteICA:</strong> {order.reteICA ? order.reteICA.toLocaleString('es-CO', { style: 'currency', currency: 'COP' }) : 'N/A'}</p>
                <p className="text-sm"><strong>ReteFuente:</strong> {order.reteFuente ? order.reteFuente.toLocaleString('es-CO', { style: 'currency', currency: 'COP' }) : 'N/A'}</p>
                <p className="text-sm"><strong>Total VAT:</strong> {order.totalVAT ? order.totalVAT.toLocaleString('es-CO', { style: 'currency', currency: 'COP' }) : 'N/A'}</p>
                <p className="text-sm"><strong>Creada:</strong> {order.createdAt ? new Date(order.createdAt).toLocaleString() : 'Fecha no disponible'}</p>
                {order.paidAt && (
                  <p className="text-sm"><strong>Pagada el:</strong> {order.paidAt ? new Date(order.paidAt).toLocaleString() : 'Fecha no disponible'}</p>
                )}
              </div>
            </article>
          ))}
        </section>
      ) : (
        <p className="text-center text-tecnofi-text">Cargando órdenes...</p>
      )}
    </Wrapper>
  );
}

export default GetOrder;