import React from 'react';
import { Wrapper } from '../../../component';
import { MainHomeProps, Section } from './types';
import { Link } from 'react-router-dom';

function MainHome({ setHome }: MainHomeProps) {

  const getGridTemplateColumns = (contentLength: number) => {
    const minColumns = 2;
    const maxColumns = 6;
    const optimalColumns = Math.min(Math.max(contentLength, minColumns), maxColumns);
    switch (optimalColumns) {
      case 2:
        return 'ml:grid-cols-2 sm:grid-cols-3 grid-cols-2';
      case 3:
        return 'ml:grid-cols-3 sm:grid-cols-3 grid-cols-2';
      case 4:
        return 'ml:grid-cols-4 sm:grid-cols-3 grid-cols-2';
      case 5:
        return 'ml:grid-cols-5 sm:grid-cols-3 grid-cols-2';
      case 6:
        return 'ml:grid-cols-6 sm:grid-cols-3 grid-cols-2';
      default:
        return 'grid-cols-2';
    }
  }

  return (
    <>
      <div className="flex flex-col justify-center">
        {setHome.content?.map((section, sectionIndex) => (
          <section
            key={sectionIndex}
            className={`flex flex-col justify-center py-8 w-full h-auto text-center ${section.isHighlighted ? 'bg-tecnofi-section' : ''
              }`}
          >
            <h4 className="flex justify-center text-xl font-medium text-tecnofi-text mb-4">
              {section.name}
            </h4>
            <Wrapper isContent>
              {section.contents && getGridTemplateColumns(section.contents.length) && (
                <div
                  className={`grid justify-center items-center gap-4 my-4 ${getGridTemplateColumns(section.contents.length)}`}
                >
                  {section.contents?.map((content, contentIndex) => (
                    <article
                      key={contentIndex}
                      className={`flex flex-col justify-center items-center text-tecnofi-text px-4`}
                    >
                      {content.url ? (
                        <Link
                          to={content.url}
                          className="flex flex-col justify-center items-center my-2 transition duration-500 hover:scale-110 cursor-pointer transition-opacity opacity-75 hover:opacity-100 ease-in-out"
                        >
                          {content.name && (
                            <h6 className="font-medium text-lg mb-2">{content.name}</h6>
                          )}
                          {content.image && (
                            <img
                              src={content.image}
                              alt={content.name}
                              className="h-auto w-auto max-w-[120px] max-h-[120px] mx-auto mb-2"
                            />
                          )}
                        </Link>
                      ) : (
                        <div
                          className="flex flex-col justify-start my-2 transition duration-500 hover:scale-110 cursor-pointer transition-opacity opacity-75 hover:opacity-100 ease-in-out"
                        >
                          {content.name && (
                            <h6 className="font-medium text-lg mb-2">{content.name}</h6>
                          )}
                          {content.image && (
                            <img
                              src={content.image}
                              alt={content.name}
                              className="h-auto w-auto max-w-[120px] max-h-[120px] mx-auto mb-2"
                            />
                          )}
                        </div>
                      )}
                    </article>
                  ))}
                </div>
              )}
            </Wrapper>
          </section>
        ))}
      </div>
    </>
  );
}

export default MainHome;