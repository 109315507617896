import React, { useState, useEffect } from 'react';
import useAuth from '../../../hooks/useAuth';
import { Wrapper } from '../../../component';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { ContentSection } from './types';
import MainBanner from './MainBanner';
import MainHome from './MainHome';
import LoginBanner from './LoginBanner';

function DynamicHome() {
  const { authToken } = useAuth();
  const [sections, setSections] = useState<ContentSection[]>([]);

  useEffect(() => {
    const fetchConfigurations = async () => {
      try {
        const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_TECNOFICOM}/contents`, { headers });
        setSections(response.data);
      } catch (error) {
        toast.error('Hubo un problema al cargar las configuraciones.');
      }
    };

    fetchConfigurations();
  }, [authToken]);

  const mainBannerSection = sections.find(section => section.name === 'Main Banner');
  const mainHomeSection = sections.find(section => section.name === 'Home');
  const mainLoginSection = sections.find(section => section.name === 'Login');

  return (
    <>
      <ToastContainer />
      {mainBannerSection && <MainBanner setBanner={mainBannerSection} />}
      {mainHomeSection && <MainHome setHome={mainHomeSection} />}
      {mainLoginSection && <LoginBanner setHome={mainLoginSection} />}
    </>
  );
}

export default DynamicHome;
