import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { OneUser, Profile, User } from './types';
import useAuth from '../../../hooks/useAuth';
import { Button } from '../../../component';
import { toast } from 'react-toastify';

interface City {
  region: string;
  departmentDaneCode: string;
  department: string;
  municipalityDaneCode: string;
  municipality: string;
}

function ChangeData() {
  const { authToken, getUserInfo } = useAuth();
  const infoUser = getUserInfo();
  const [user, setUser] = useState<User | null>(null);
  const [profile, setProfile] = useState<Profile | null>(null);

  const [toggleSelected, setToggleSelected] = useState(false);
  const [cities, setCities] = useState<City[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [toggleSelectedC, setToggleSelectedC] = useState(false);

  const [fullName, setFullName] = useState('');
  const [position, setPosition] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [primaryAddress, setPrimaryAddress] = useState('');
  const [secondaryAddress, setSecondaryAddress] = useState<string | null>('');
  const [location, setLocation] = useState<City>();
  const [clientType, setClientType] = useState<string | null>(null);
  const [dataTreatmentAuthorization, setDataTreatmentAuthorization] = useState<boolean>(false);
  const [notificationAuthorization, setNotificationAuthorization] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get<OneUser>(`${process.env.REACT_APP_BACKEND_TECNOFICOM}/auth/${infoUser?.id}`, {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        });
        setUser(response.data.getUser);
        setProfile(response.data.profile);

        if (response.data.getUser) {
          setClientType(response.data.getUser.clientType);
        }
        if (response.data.profile) {
          setFullName(response.data.profile.fullName);
          setPosition(response.data.profile.position);
          setContactPhone(response.data.profile.contactPhone);
          setPrimaryAddress(response.data.profile.primaryAddress);
          setSecondaryAddress(response.data.profile.secondaryAddress);
          setLocation(response.data.profile.location);
          setDataTreatmentAuthorization(response.data.profile.dataTreatmentAuthorization);
          setNotificationAuthorization(response.data.profile.notificationAuthorization);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchCities = async () => {
      try {
        const response = await fetch('https://www.datos.gov.co/resource/xdk5-pm3f.json');
        const data = await response.json();
        const cityList: City[] = data.map((item: any) => ({
          region: item.region,
          departmentDaneCode: item.c_digo_dane_del_departamento,
          department: item.departamento,
          municipalityDaneCode: item.c_digo_dane_del_municipio,
          municipality: item.municipio
        }));
        setCities(cityList);
      } catch (error) {
        console.error('Error fetching cities:', error);
      }
    };

    fetchCities();
    fetchData();
  }, [authToken]);

  const handleUserSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!user) {
      toast.error('Error: el usuario no está definido.');
      return;
    }

    if (clientType === user.clientType) {
      toast.info('No se realizaron cambios en el tipo de cliente.');
      return;
    }
    try {
      await axios.patch(
        `${process.env.REACT_APP_BACKEND_TECNOFICOM}/auth/update/${user.id}`,
        { clientType },
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      toast.success('¡Actualización realizada!');
    } catch (error) {
      console.error('Error actualizando cliente:', error);
      toast.error('Error al actualizar el cliente. Intenta nuevamente.');
    }
  };


  const handleProfileSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    if (!profile) {
      toast.error('Error: el perfil no está definido.');
      return;
    }
  
    const updatedFields: Partial<Profile> = {};
  
    if (fullName !== profile.fullName) {
      updatedFields.fullName = fullName;
    }
    if (position !== profile.position) {
      updatedFields.position = position;
    }
    if (contactPhone !== profile.contactPhone) {
      updatedFields.contactPhone = contactPhone;
    }
    if (primaryAddress !== profile.primaryAddress) {
      updatedFields.primaryAddress = primaryAddress;
    }
    if (secondaryAddress !== profile.secondaryAddress) {
      updatedFields.secondaryAddress = secondaryAddress;
    }
    if (location !== profile.location) {
      updatedFields.location = location;
    }
    if (dataTreatmentAuthorization !== profile.dataTreatmentAuthorization) {
      updatedFields.dataTreatmentAuthorization = dataTreatmentAuthorization;
    }
    if (notificationAuthorization !== profile.notificationAuthorization) {
      updatedFields.notificationAuthorization = notificationAuthorization;
    }
  
    if (Object.keys(updatedFields).length === 0) {
      toast.info('No se realizaron cambios en el perfil.');
      return;
    }
  
    try {
      await axios.patch(
        `${process.env.REACT_APP_BACKEND_TECNOFICOM}/profile`,
        updatedFields,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      toast.success('Información del perfil actualizada exitosamente');
    } catch (error) {
      console.error('Error actualizando perfil:', error);
      toast.error('Error al actualizar el perfil. Intenta nuevamente.');
    }
  };

  const filteredCities = cities.filter(city =>
    city.municipality.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const toggleClientType = (client: string) => {
    setClientType(client);
    setToggleSelected(false);
  };

  const toggleSelectedCity = (city: City) => {
    setLocation(city);
    setToggleSelectedC(false);
  };

  const handleDataTreatmentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDataTreatmentAuthorization(e.target.checked);
  };

  const handleNotificationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNotificationAuthorization(e.target.checked);
  };

  return (
    <div className="container text-tecnofi-text mx-auto p-4">
      <h2 className="text-tecnofi-text text-xl font-semibold text-center mt-6 mb-2">
        Cambiar datos
      </h2>
      <section className="grid grid-cols-2 bg-tecnofi-section shadow-md p-4 mb-6 gap-4 rounded-lg">
        <article className="col-span-2 md:col-span-1">
          <h3 className="text-lg font-semibold mb-4">Editar Información del Cliente</h3>

          <form onSubmit={handleUserSubmit}>

            <div className="relative mb-4">
              <label className="flex text-base font-medium mb-2">
                Seleccionar el Tipo de Cliente
              </label>
              <label
                className="flex justify-between items-center bg-tecnofi-section font-normal text-base text-tecnofi-text border-[2px] border-tecnofi-primary px-[16px] py-[8px] rounded-[8px] h-[52px] w-full cursor-pointer"
                onClick={() => setToggleSelected(!toggleSelected)}
              >
                {clientType ? clientType : 'Seleccionar Cliente'}
              </label>
              {toggleSelected && (
                <div className="absolute top-full left-0 right-0 bg-tecnofi-background border border-tecnofi-primary rounded-lg mt-1 z-50 overflow-hidden shadow-md">
                  <div className="py-2 px-4 cursor-pointer hover:bg-tecnofi-section" onClick={() => toggleClientType('CLIENT_FINAL')}>
                    Cliente final
                  </div>
                  <div className="py-2 px-4 cursor-pointer hover:bg-tecnofi-section" onClick={() => toggleClientType('DISTRIBUTOR')}>
                    Distribuidor de Tecnología
                  </div>
                  <div className="py-2 px-4 cursor-pointer hover:bg-tecnofi-section" onClick={() => toggleClientType('SOLUTION_INTEGRATOR')}>
                    Integrador de soluciones
                  </div>
                  <div className="py-2 px-4 cursor-pointer hover:bg-tecnofi-section" onClick={() => toggleClientType('GOVERMENT')}>
                    Gobierno
                  </div>
                  <div className="py-2 px-4 cursor-pointer hover:bg-tecnofi-section" onClick={() => toggleClientType('TECHNICAL')}>
                    Técnico
                  </div>
                </div>
              )}
            </div>

            <Button
              variant="Primary"
              type="submit"
              className="font-medium py-2 px-4 rounded-lg transition-colors"
            >
              Guardar Cambios del Usuario
            </Button>
          </form>
        </article>

        <article className="col-span-2 md:col-span-1">
          <h3 className="text-lg font-semibold mb-4">Editar Información del Perfil</h3>
          <form onSubmit={handleProfileSubmit}>
            <div className="mb-4">
              <label className="flex text-base font-medium mb-2" htmlFor="nombre">
                Nombre Completo
              </label>
              <input
                className="border-[2px] border-tecnofi-primary rounded-[8px] w-full py-3 px-4 bg-tecnofi-section focus:outline-none"
                id="nombre"
                type="text"
                placeholder="Nombre Completo"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
            </div>

            <label className="flex text-base font-medium mb-2" htmlFor="telefono">
              Teléfono de Contacto
            </label>
            <input
              className="border-[2px] border-tecnofi-primary rounded-[8px] w-full py-3 px-4 bg-tecnofi-section focus:outline-none"
              id="telefono"
              type="tel"
              placeholder="Teléfono de Contacto"
              value={contactPhone}
              onChange={(e) => setContactPhone(e.target.value)}
            />

            <div className="mb-4">
              <label className="flex text-base font-medium mb-2" htmlFor="position">
                Cargo
              </label>
              <input
                className="border-[2px] border-tecnofi-primary rounded-[8px] w-full py-3 px-4 bg-tecnofi-section focus:outline-none"
                id="position"
                type="text"
                placeholder="Cargo"
                value={position}
                onChange={(e) => setPosition(e.target.value)}
              />
            </div>

            <div className="mb-4">
              <label className="flex text-base font-medium mb-2" htmlFor="primaryAddress">
                Direccion Principal
              </label>
              <input
                className="border-[2px] border-tecnofi-primary rounded-[8px] w-full py-3 px-4 bg-tecnofi-section focus:outline-none"
                id="primaryAddress"
                type="text"
                placeholder="Direccion Principal"
                value={primaryAddress}
                onChange={(e) => setPrimaryAddress(e.target.value)}
              />
            </div>

            <div className="mb-4">
              <label className="flex text-base font-medium mb-2" htmlFor="secondaryAddress">
                Direccion Secundaria (Opcional)
              </label>
              <input
                className="border-[2px] border-tecnofi-primary rounded-[8px] w-full py-3 px-4 bg-tecnofi-section focus:outline-none"
                id="secondaryAddress"
                type="text"
                placeholder="Direccion Secundaria"
                value={secondaryAddress || ''}
                onChange={(e) => setSecondaryAddress(e.target.value)}
              />
            </div>

            <div className="relative mb-4">
              <label className="flex text-base font-medium mb-2">Selecciona tu Ciudad</label>
              <label
                className="flex justify-between items-center bg-tecnofi-section font-normal text-base text-tecnofi-text border-[2px] border-tecnofi-primary px-[16px] py-[8px] rounded-[8px] h-[52px] w-full cursor-pointer"
                onClick={() => setToggleSelectedC(!toggleSelectedC)}
              >
                {location?.municipality ? location?.municipality : 'Seleccionar Ciudad'}
              </label>

              {toggleSelectedC && (
                <div className="absolute top-full left-0 right-0 bg-tecnofi-background border border-tecnofi-primary rounded-lg mt-1 z-50 overflow-hidden shadow-md max-h-60 overflow-y-auto">
                  <input
                    type="text"
                    placeholder="Buscar ciudad..."
                    className="py-2 px-4 w-full border-b-[1px] border-tecnofi-primary focus:outline-none"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    autoComplete='off'
                  />

                  {filteredCities.map((city, index) => (
                    <div
                      key={index}
                      className="py-2 px-4 cursor-pointer hover:bg-tecnofi-section"
                      onClick={() => toggleSelectedCity(city)}
                    >
                      {city.municipality}
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="flex items-center space-x-2 mb-4">
              <input
                className="h-5 w-5 accent-tecnofi-primary border border-tecnofi-primary rounded-sm transition duration-300 ease-in-out"
                type="checkbox"
                id="dataTreatmentAuthorization"
                checked={dataTreatmentAuthorization}
                onChange={handleDataTreatmentChange}
              />
              <label className="text-base font-medium" htmlFor="dataTreatmentAuthorization">
                Autorización para el tratamiento de datos
              </label>
            </div>

            <div className="flex items-center space-x-2 mb-4">
              <input
                className="h-5 w-5 accent-tecnofi-primary border border-tecnofi-primary rounded-sm transition duration-300 ease-in-out"
                type="checkbox"
                id="notificationAuthorization"
                checked={notificationAuthorization}
                onChange={handleNotificationChange}
              />
              <label className="text-base font-medium" htmlFor="notificationAuthorization">
                Autorización para notificaciones
              </label>
            </div>

            <Button
              variant="Primary"
              type="submit"
              className="font-medium py-2 px-4 rounded-lg transition-colors"
            >
              Guardar Cambios del Perfil
            </Button>
          </form>
        </article>
      </section>
    </div>
  );
}

export default ChangeData;
