import React from 'react';
import { Link } from 'react-router-dom';
import useFetchTheme from '../../../hooks/useFetchTheme';

interface LogoNavbarProps {
  className?: string;
}

function LogoNavbar({ className }: LogoNavbarProps) {
  const theme = useFetchTheme();
  return (
    <Link to={"/"} className="flex-shrink-0">
      <img src={`${theme.logo}`} alt="Logo Tecnoficom" className={`hidden md:flex ${className}`} />
      <img src={`${theme.icon}`} alt="Logo Tecnoficom" className={`md:hidden h-[50px] w-[46px] ${className}`} />
    </Link>
  );
}

export default LogoNavbar;
