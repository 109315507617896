import { Button } from '../../../component';
import useAuth from '../../../hooks/useAuth';
import axios from 'axios';
import { MdOutlineSimCardDownload } from 'react-icons/md';
import { toast } from 'react-toastify';

function ReportSales() {
  const { authToken } = useAuth();

  const downloadReport = async () => {
    try {
      const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_TECNOFICOM}/reports/generate-stock`,
        { headers, responseType: 'blob' }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `stock_report.xlsx`);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      toast.success('Archivo descargado.');
    } catch (error) {
      toast.error('Hubo un problema al descargar el archivo.');
    }
  };

  return (
    <section className="flex flex-col p-6 bg-tecnofi-section text-tecnofi-text rounded-lg shadow-md mb-4">
      <h3 className="text-lg font-semibold mb-4">Descargar Reporte de Inventario</h3>

      <Button
        variant="Primary"
        onClick={downloadReport}
        className="flex items-center gap-2 bg-tecnofi-button text-white px-2 py-2 rounded-md"
      >
        <MdOutlineSimCardDownload
          size={22}
          fill="#FFF"
        />
        Descargar Reporte
      </Button>
    </section>
  );
}

export default ReportSales;