import React, { useEffect, useState, useRef } from 'react';
import useAuth from '../../../hooks/useAuth';
import axios from 'axios';
import { Button, Wrapper } from '../../../component';
import { Link } from 'react-router-dom';
import { MdOutlineCreate } from 'react-icons/md';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

function ProductImages() {
  const { authToken } = useAuth();
  const [images, setImages] = useState<string[]>([]);
  const [totalPaginas, setTotalPaginas] = useState(1);
  const paginaRef = useRef(1);

  const fetchImages = async (pagina: number) => {
    try {
      const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_TECNOFICOM}/files/product`,
        {
          headers,
          params: { offset: (pagina - 1) * 15, limit: 15 },
        }
      );
      setImages(response.data.images);
      setTotalPaginas(response.data.totalPages);
    } catch (error) {
      console.error("Error al obtener los datos del producto:", error);
    }
  };
  useEffect(() => {
    fetchImages(paginaRef.current);
  }, [authToken]);

  const handleNextPage = () => {
    if (paginaRef.current < totalPaginas) {
      paginaRef.current += 1;
      fetchImages(paginaRef.current);
    }
  };

  const handlePreviousPage = () => {
    if (paginaRef.current > 1) {
      paginaRef.current -= 1;
      fetchImages(paginaRef.current);
    }
  };

  return (
    <Wrapper>
      <section className="flex justify-between items-center w-full">
        <span className="flex items-center gap-2">
          <Link
            to={`/dashboard/admin-products/images/upload`}
            className="bg-tecnofi-primary rounded-[8px] p-[8px] text-white"
          >
            <MdOutlineCreate size="16" fill="#fff" />
          </Link>
          <p className="text-tecnofi-text text-base">
            Crear
          </p>
        </span>
        <h3 className="flex-1 text-tecnofi-text text-lg font-semibold text-center mt-6 mb-2">
          Administración de Imagenes de Productos
        </h3>
      </section>
      <section>
        {totalPaginas > 1 && (
          <section className="flex justify-between items-center font-medium m-4">
            <Button
              type="button"
              className="rounded-lg p-[8px] bg-tecnofi-primary text-white"
              onClick={handlePreviousPage}
              disabled={paginaRef.current === 1}
            >
              <FaAngleLeft size="22" fill="#fff" />
            </Button>
            <span className="text-tecnofi-text text-center font-medium">
              Página {paginaRef.current} de {totalPaginas}
            </span>
            <Button
              type="button"
              className="rounded-lg p-[8px] bg-tecnofi-primary text-white"
              onClick={handleNextPage}
              disabled={paginaRef.current === totalPaginas}
            >
              <FaAngleRight size="22" fill="#fff" />
            </Button>
          </section>
        )}
      </section>
      <section>
        {images.length > 0 && (
          <article>
            <article>
              {images.map((image, index) => (
                <div key={index} className="flex items-center w-full gap-2">
                  <img src={`${image}`} alt="" className="h-14 w-14" />
                  <Link
                    to={image}
                    className="text-tecnofi-text font-medium hover:text-tecnofi-primary transition-colors duration-300 break-words max-w-[80%]"
                  >
                    {image}
                  </Link>
                </div>
              ))}
            </article>
          </article>
        )}
      </section>
    </Wrapper>
  );
}

export default ProductImages;