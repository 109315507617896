import React, { useEffect, useState } from 'react';
import ContentForm from './ContentForm';
import { ContentItem, SectionFormProps } from './types';
import { Link } from 'react-router-dom';
import { Button } from '../../../component';
import { TiDocumentDelete, TiEdit } from 'react-icons/ti';

function SectionForm({
  onSave,
  section = null,
  contentIndex = null,
}: SectionFormProps) {
  const [name, setName] = useState('');
  const [isHighlighted, setIsHighlighted] = useState(false);
  const [contents, setContents] = useState<ContentItem[]>([]);
  const [editingContentIndex, setEditingContentIndex] = useState<number | null>(null);

  useEffect(() => {
    if (contentIndex !== null && section?.content && Array.isArray(section.content)) {
      const content = section.content[contentIndex];
      if (content) {
        setContents(content.contents || []);
        setName(content.name || '');
        setIsHighlighted(content.isHighlighted || false);
      }
    }
  }, [contentIndex, section]);

  const handleAddOrUpdateContent = (contentData: ContentItem, index: number | null) => {
    if (index !== null) {
      const updatedContents = [...contents];
      updatedContents[index] = contentData;
      setContents(updatedContents);
    } else {
      setContents([...contents, contentData]);
    }
    setEditingContentIndex(null);
  };

  const handleSave = () => {
    onSave({ name, isHighlighted, contents }, contentIndex);
    setName('');
    setIsHighlighted(false);
    setContents([]);
  };

  const handleDeleteContent = (index: number) => {
    const updatedContents = contents.filter((_, i) => i !== index);
    setContents(updatedContents);
  };

  const handleEdit = (index: number) => {
    setEditingContentIndex(index);
  };

  return (
    <article className="mb-6 border-[2px] border-tecnofi-primary p-4 rounded-lg bg-tecnofi-background">
      <input
        type="text"
        placeholder="Nombre de la sección"
        className="bg-tecnofi-background font-medium border-[2px] border-tecnofi-primary rounded-[8px] w-full py-2 px-4 mb-2 focus:outline-none"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <div className="flex items-center space-x-3 mb-2">
        <input
          className="h-5 w-5 accent-tecnofi-primary border border-tecnofi-primary rounded-sm transition duration-300 ease-in-out"
          type="checkbox"
          id="notificationAuthorization"
          checked={isHighlighted}
          onChange={(e) => setIsHighlighted(e.target.checked)}
        />
        <label className="text-base font-medium" htmlFor="notificationAuthorization">
          ¿Sección destacada?
        </label>
      </div>

      <ContentForm
        onSave={handleAddOrUpdateContent}
        content={contents}
        contentItemIndex={editingContentIndex}
      />

      <h4 className="mt-4 mb-2 font-semibold">Contenidos agregados:</h4>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-4">
        {contents.map((content, index) => (
          <span key={index} className="relative bg-tecnofi-section p-4 border-[2px] border-tecnofi-primary rounded bg-white shadow-sm flex flex-col justify-between">
            <div>
              {content.name && (
                <p className="mb-2">
                  <span className="font-medium">Nombre del Contenido: </span>
                  {content.name}
                </p>
              )}
              {content.image && (
                <div className="mb-2">
                  <span className="font-medium">Imagen del Contenido: </span>
                  <img
                    src={content.image}
                    alt={content.name || 'Imagen del contenido'}
                    className="h-auto w-auto max-h-40 rounded border-[2px] border-tecnofi-primary mx-auto mb-2 object-cover"
                  />
                </div>
              )}
              {content.url && (
                <p className="mb-2">
                  <span className="font-medium">Link del Contenido: </span>
                  <Link
                    to={content.url}
                    className="text-tecnofi-primary line-clamp-1 underline"
                  >
                    {content.url}
                  </Link>
                </p>
              )}
            </div>

            <span className="flex flex-col absolute top-1 right-1">
              <Button
                variant="Primary"
                className="text-[#FFF] rounded-lg p-[4px]"
                onClick={() => handleEdit(index)}
              >
                <TiEdit size={26} fill="#FFF" />
              </Button>
              <Button
                variant="Primary"
                className="text-[#FFF] rounded-lg p-[4px]"
                onClick={() => handleDeleteContent(index)}
              >
                <TiDocumentDelete size={26} fill="#FFF" />
              </Button>
            </span>
          </span>
        ))}
      </div>
      <Button
        variant="Primary"
        onClick={handleSave}
        className="px-4 py-2 rounded-lg"
      >
        Guardar Sección
      </Button>
    </article>
  );
}

export default SectionForm;